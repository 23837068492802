import { v4 as uuid } from "uuid";
import { Message } from "../Message";

/**
 * Primarily used for sending messages between modules
 * and systems across the external ecosystem (e.g. network).
 */
export function Envelope (message, { ...rest } = {}) {
	return {
		id: uuid(),
		type: "request",
		message: Message(message),	// Basically, enforce the Message interface
		timestamp: Date.now(),
		...rest,
	};
};

export default Envelope;