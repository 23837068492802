import React from "react";
import { BsCircleFill } from "react-icons/bs";

export function Radio ({ checked, onChange, name, value, children }) {
	return (
		<label className="flex items-center space-x-2 cursor-pointer">
			<div
				className={ `w-4 h-4 rounded-full border border-neutral-300 flex items-center justify-center transition ${ checked ? "bg-sky-500 border-sky-500" : "" }` }
			>
				{ checked && <BsCircleFill className="w-2 h-2 rounded-full text-white" /> }
			</div>
			<input type="radio" checked={ checked } value={ value } onChange={ onChange } name={ name } className="hidden" />
			{ children }
		</label>
	);
}

export default Radio;