import React from "react";
import { EnumFieldType } from "../lib/EnumFieldType";

import { FieldInput } from "./fields/FieldInput";
import { FieldToggle } from "./fields/FieldToggle";
import { FieldRadio } from "./fields/FieldRadio";
import { FieldCheckbox } from "./fields/FieldCheckbox";
import { FieldDropdown } from "./fields/FieldDropdown";

export const EnumFieldComponent = {
	[ EnumFieldType.TEXT ]: FieldInput,
	[ EnumFieldType.NUMBER ]: FieldInput,
	[ EnumFieldType.BOOLEAN ]: FieldToggle,
	[ EnumFieldType.RADIO ]: FieldRadio,
	[ EnumFieldType.CHECKBOX ]: FieldCheckbox,
	[ EnumFieldType.DROPDOWN ]: FieldDropdown,
};

export function FormField ({ field, ...props }) {
	const { type } = field;
	const Component = EnumFieldComponent[ type ] ?? null;

	if (!Component) {
		return null;
	}

	return (
		<Component field={ field } { ...props } />
	);
};

export default FormField;