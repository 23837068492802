import React, { useEffect } from "react";
import { Checkbox } from "../Checkbox";

export function FieldCheckbox ({ state, field, next, ...rest }) {
	const { name, attr, options } = field;
	const [ selectedValues, setSelectedValues ] = React.useState(() => {
		if (state && Array.isArray(state)) {
			return state;
		} else if (attr?.defaultValue && Array.isArray(attr.defaultValue)) {
			return attr.defaultValue;
		}

		return [];
	});

	const handleChange = (e) => {
		const value = e.target.value;
		setSelectedValues((prevSelectedValues) => {
			if (prevSelectedValues.includes(value)) {
				return prevSelectedValues.filter((v) => v !== value);
			} else {
				return [ ...prevSelectedValues, value ];
			}
		});
	};

	useEffect(() => {
		if (selectedValues == null) return;
		next(selectedValues);
	}, [ selectedValues ]);

	return (
		<div className="flex flex-row gap-2 select-none">
			{ options.map((option, index) => {
				const value = typeof option === "object" ? option.value : option;
				const label = typeof option === "object" ? option.label : option;
				const checked = selectedValues.includes(value);

				return (
					<label key={ index } className="inline-flex items-center cursor-pointer">
						<Checkbox
							name={ name }
							value={ value }
							onChange={ handleChange }
							checked={ checked }
							{ ...rest }
						/>
						<span className="ml-2 text-center">{ label }</span>
					</label>
				);
			}) }
			<input type="hidden" name={ name } value={ selectedValues } />
		</div>
	);
};

export default FieldCheckbox;