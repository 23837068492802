import * as XLSX from "xlsx";
import { BsFiletypeXlsx } from "react-icons/bs";

import { EnumActions } from "../../modules/message/EnumActions";
import { Loading } from "../utility/Loading";
import { ResponsivePlot } from "../plotly/ResponsivePlot";
import { Table } from "../table/normal/Table";
import { Table as TableGrid } from "../table/grid/Table";

import { Banner } from "../Banner";
import { DBErrorBanner } from "../DBErrorBanner";
import { AsyncStatus } from "../AsyncStatus";

/**
 * Download"s the current BuyerSummary and BuyerSellerSummary as an Excel file.
 */
const DownloadButton = ({ selected, data }) => {
	const { buyerSummary, buyerSellerSummary } = data;

	const handleDownload = () => {
		const wb = XLSX.utils.book_new();
		const ws_bs = XLSX.utils.json_to_sheet(buyerSummary);
		const ws_bss = XLSX.utils.json_to_sheet(buyerSellerSummary);
		XLSX.utils.book_append_sheet(wb, ws_bss, `BuyerSeller`);
		XLSX.utils.book_append_sheet(wb, ws_bs, `Buyer`);
		XLSX.writeFile(wb, `${ selected?.DTXID }-BSRS-${ (new Date()).toISOString().slice(0, 10) }.xlsx`);
	};

	return (
		<button
			className="flex items-center"
			onClick={ handleDownload }
			title={ `Download BuyerSellerRecommendationSummary for ${ selected?.DTXID }` }
		>
			<BsFiletypeXlsx
				className="text-emerald-600 hover:text-emerald-700"
				size={ 24 }
			/>
		</button>
	);
};

export const BuyerSummary = ({ summary }) => {
	const { formData, updateForm, isProvisional, selected, solutionSpace, buyer, buyerSeller } = summary;

	const buyerSummary = buyer?.state?.payload?.[ 0 ];
	const buyerSellerSummary = buyerSeller?.state?.payload?.[ 0 ];
	const plotlyData = solutionSpace?.state?.payload?.[ selected?.DTXID ]?.plotjson;
	const notes = solutionSpace?.state?.payload?.[ selected?.DTXID ]?.plotnotes.map(note => {
		const kvp = Object.entries(note);

		return kvp.length ? kvp[ 0 ] : null;
	}).filter(note => note !== null);
	const pointData = Object.values(solutionSpace?.state?.payload?.[ selected?.DTXID ]?.plotdata ?? {});

	console.log(plotlyData)

	return (
		<div className="flex flex-col gap-2 gap-y-6 w-full">
			<div className="flex flex-col w-full items-center justify-center p-2">
				<div className="flex justify-between items-center w-full">
					<h1 className="text-xl font-bold text-neutral-700 w-full text-center">
						{ isProvisional ? "Provisional" : "Current" } Buyer Summary
					</h1>
					{
						buyer?.status === "complete" && <DownloadButton
							selected={ selected }
							data={ { buyerSummary, buyerSellerSummary } }
						/>
					}
				</div>

				<AsyncStatus
					status={ buyer?.status }
					onLoading={ () => <Loading text="Loading Buyer Summary..." /> }
					onComplete={ () => {
						return (
							<>
								{
									buyerSummary?.length ? (
										<TableGrid
											data={ buyerSummary }
											asRecords={ true }
											onRowSelect={ row => navigator.clipboard.writeText(row.toString()) }
											showFilter={ false }
											showHeader={ false }
											showFooter={ false }
											maxFieldsPerRow={ 5 }
											transformer={ (row, headers) => {
												for (let i = 0; i < headers.length; i++) {
													let header = headers[ i ];

													if (header === "DefaultSolveType") {
														if (row[ i ] === 1) {
															row[ i ] = "Max Gross Profit Margin";
														} else if (row[ i ] === 2) {
															row[ i ] = "Max Close Rate";
														}
													} else if (typeof row[ i ] === "number" || /^\d+$/.test(row[ i ])) {
														if (header.includes("Rate") || header.includes("Percent") || header === "CurrentMargin") {
															row[ i ] = (row[ i ] * 100).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %";
														} else if (header.includes("Revenue") || header.includes("Dollar")) {
															row[ i ] = "$ " + row[ i ].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
														} else {
															row[ i ] = row[ i ].toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
														}
													}
												}

												return row;
											} }
										/>
									) : (
										<Banner color="amber" header="Warning" body="No Buyer Summary data was returned." />
									)
								}
							</>
						);
					} }
					onError={ () => <DBErrorBanner source={ buyer?.state } /> }
				/>
			</div>

			<div className="flex flex-col w-full items-center justify-center p-2">
				<h1 className="text-xl font-bold text-neutral-700">{ isProvisional ? "Provisional" : "Current" } Solution Space</h1>

				<AsyncStatus
					status={ solutionSpace?.status }
					onLoading={ () => <Loading text="Loading Solution Space..." /> }
					onComplete={ () => {
						let buyerClosestPoint = null,
							formClosestPoint = null;

						// Find the closest point rounding down
						pointData.forEach(point => {
							const target = point.TargetCloseRate?.toFixed(4);
							if (target <= buyerSummary?.[ 0 ]?.FinalCloseRate?.toFixed(4) && (buyerClosestPoint === null || target > buyerClosestPoint)) {
								buyerClosestPoint = target;
							}
							if (target <= formData?.TargetCloseRate?.toFixed(4) && (formClosestPoint === null || target > formClosestPoint)) {
								formClosestPoint = target;
							}
						});

						return (
							<div className="flex flex-col w-full p-2">
								<div className="flex flex-col w-full mb-2">
									{
										notes?.map((([ title, body ], i) => (
											<Banner key={ i } color="sky" header={ title } body={ body } />
										)))
									}
								</div>
								<div className="flex w-full">
									{ (buyerSummary && buyerSummary?.[ 0 ]) && (
										<div className="w-1/3 text-xs max-h-[80vh] overflow-y-hidden">
											<table className="w-full table-auto">
												<thead>
													<tr className="border-b-2 border-b-neutral-100">
														<th className="font-semibold text-center">Target Close Rate</th>
														<th className="font-semibold text-center">Final Close Rate</th>
														<th className="font-semibold text-center">Margin Dollar</th>
														<th className="font-semibold text-center">Unreturned Leads</th>
													</tr>
												</thead>
											</table>
											<div className="overflow-y-scroll max-h-[70vh]">
												<table className="w-full table-auto">
													<tbody>
														{
															pointData.map((point, i) => {
																const {
																	TargetCloseRate,
																	FinalCloseRate,
																	Margin,
																	UnreturnedLeads,
																} = point;

																let color = "bg-white hover:bg-neutral-100 border-b-neutral-100";
																if (parseFloat(buyerClosestPoint).toFixed(4) === TargetCloseRate?.toFixed(4)) {
																	if (isProvisional) {
																		color = "bg-rose-100 hover:bg-rose-200 border-b-rose-200 hover:border-b-rose-300";
																	} else {
																		color = "bg-amber-100 hover:bg-amber-200 border-b-amber-200 hover:border-b-amber-300";
																	}
																} else if (parseFloat(formClosestPoint).toFixed(4) === TargetCloseRate?.toFixed(4)) {
																	color = "bg-sky-100 hover:bg-sky-200 border-b-sky-200 hover:border-b-sky-300";
																}

																return (
																	<tr
																		key={ i }
																		className={ `text-center cursor-pointer border-b ${ color }` }
																		onClick={ e => updateForm({
																			...formData,
																			TargetCloseRate,
																		}) }
																	>
																		<td className="py-1">{ (TargetCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %" }</td>
																		<td className="py-1">{ (FinalCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %" }</td>
																		<td className="py-1">{ "$ " + Margin?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</td>
																		<td className="py-1">{ UnreturnedLeads?.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</td>
																	</tr>
																);
															})
														}
													</tbody>
												</table>
											</div>
										</div>
									) }

									<div className="w-2/3">
										{
											plotlyData ? (
												<ResponsivePlot
													{ ...plotlyData }
													onClick={ ({ points }) => {

														//TODO: Use the .plotdata[i].SellerDetail[SellerDTXID][] to get Solution Space lead qty data for side chart
														// On click, update the new side chart with this data -- maybe some barchart stack or something?
														//TODO: Use the .plotnotes[] (each entry will be a kvp object of header:body)

														if (points.length > 0) {
															const { x: closeRate, y: marginDollar } = points[ 0 ];
															const { DefaultSolveType, MinVolume, RevenuePerLead } = formData;

															if (DefaultSolveType === 1) {	// Max Gross Profit Margin
																updateForm({
																	...formData,
																	TargetCloseRate: closeRate,
																});
															} else if (DefaultSolveType === 2) {	// Max Close Rate
																let marginPercent = marginDollar / MinVolume / RevenuePerLead;

																updateForm({
																	...formData,
																	MarginPercent: marginPercent,
																});
															}
														}
													} }
												/>
											) : (
												<Banner color="amber" header="Warning" body="No Solution Space data was returned." />
											)
										}
									</div>
								</div>
							</div>
						);
					} }
					onError={ () => {
						const { type, payload } = solutionSpace?.state;

						if (type === EnumActions.Packet.DecisionEngine.CHART_ERROR) {
							if (payload?.finish_in_seconds) {
								const { error_message, finish_in_seconds } = payload;
								return (
									<Banner
										color="rose"
										header={ error_message }
										body={ `Estimated time remaining: ${ finish_in_seconds } seconds` }
									/>
								);
							} else if (typeof payload === "string") {
								return (
									<Banner
										color="rose"
										header={ "Python Error" }
										body={ payload }
									/>
								);
							}
						} else if (type == null) {
							return (
								<Banner
									color="rose"
									header="Python Error"
									body={ `An empty response was returned from the python service` }
								/>
							);
						}

						return (
							<Banner
								color="rose"
								header="Error"
								body="An unspecified error has occurred"
							/>
						);
					} }
				/>
			</div>

			<div className="flex flex-col w-full p-2">
				<div className="flex justify-between items-center w-full">
					<h1 className="text-xl font-bold text-neutral-700 w-full text-center">
						{ isProvisional ? "Provisional" : "Current" } Buyer-Seller Summary
					</h1>
					{
						buyerSeller?.status === "complete" &&
						<DownloadButton
							selected={ selected }
							data={ { buyerSummary, buyerSellerSummary } }
						/>
					}
				</div>

				<AsyncStatus
					status={ buyerSeller?.status }
					onLoading={ () => <Loading text="Loading Buyer-Seller Summary..." /> }
					onComplete={ () => {
						return (
							<>
								{
									buyerSellerSummary?.length ? (
										<Table
											data={ buyerSellerSummary }
											asRecords={ true }
											transformer={ (row, headers) => {
												for (let key in row) {
													let header = headers[ key ];

													if (/^\d{4}-\d{2}-\d{2}.+$/.test(row[ key ])) {	// Has root form of: YYYY-MM-DD
														row[ key ] = (new Date(row[ key ])).toISOString().slice(0, 10);
													} else if (typeof row[ key ] === "number" || /^\d+$/.test(row[ key ])) {
														if (header.includes("Revenue") || header.includes("Price")) {
															row[ key ] = "$ " + row[ key ].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
														} else if (header.includes("Rate") || header.includes("Percent") || header === "CurrentMargin") {
															row[ key ] = (row[ key ] * 100).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %";
														} else if (header.includes("TakeRate")) {
															row[ key ] = row[ key ].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
														} else if (header.includes("MustTake") || header.includes("HasMonthlyCapOverride")) {
															row[ key ] = <div className={ `` + (row[ key ] === 1 ? "text-emerald-500" : "text-rose-500") }>{ row[ key ] === 1 ? "Yes" : "No" }</div>;
														} else if (header.includes("EstPingThreshold") || header.includes("CurrentThreshold")) {
															row[ key ] = row[ key ].toLocaleString("en-US", { minimumFractionDigits: 4, maximumFractionDigits: 4 });
														} else {
															row[ key ] = row[ key ].toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
														}
													}
												}

												return row;
											} }
										/>
									) : (
										<Banner color="amber" header="Warning" body="No Buyer-Seller Summary data was returned." />
									)
								}
							</>
						);
					} }
					onError={ () => <DBErrorBanner source={ buyerSeller?.state } /> }
				/>
			</div>
		</div>
	);
};

export default BuyerSummary;