import React from "react";
import { BsCheck } from "react-icons/bs";

export function Checkbox ({ checked, onChange, value, name, children }) {
	return (
		<label className="flex items-center space-x-2 cursor-pointer">
			<div
				className={ `w-4 h-4 rounded-md border border-neutral-300 flex items-center justify-center transition ${ checked ? "bg-sky-500 border-sky-500" : "" }` }
			>
				{ checked && (
					<BsCheck className="w-4 h-4 text-white fill-current" />
				) }
			</div>
			<input type="checkbox" checked={ checked } value={ value } onChange={ onChange } name={ name } className="hidden" />
			{ children }
		</label>
	);
}

export default Checkbox;