import React from "react";
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronRight, BsChevronDoubleRight } from "react-icons/bs";

export function TablePagination ({
	currentPage,
	totalPages,
	handlePageChange,
	handleFirstPage,
	handleLastPage,
	rowsPerPage,
	handleRowsPerPageChange,
	rowsPerPageOptions,
	indexOfFirstRow,
	indexOfLastRow,
	filteredRowsLength,
	maxPageButtons = 7,
}) {

	const halfMaxButtons = Math.floor(maxPageButtons / 2);
	let startPage = Math.max(1, currentPage - halfMaxButtons);
	let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

	// Re-adjust the startPage if the endPage is closer to totalPages
	startPage = Math.max(1, endPage - maxPageButtons + 1);

	return (
		<div className="flex justify-between items-center mt-4 select-none w-full">
			<div className="mr-4">
				<div className="text-neutral-500 text-xs">
					Showing { indexOfFirstRow + 1 } to { Math.min(indexOfLastRow, filteredRowsLength) } of { filteredRowsLength } rows
				</div>
			</div>

			<div className="space-x-2 flex items-center text-xs">
				<button
					onClick={ handleFirstPage }
					disabled={ currentPage === 1 }
					className="border border-neutral-300 p-2 rounded-md focus:outline-none shadow-sm hover:bg-neutral-200 cursor-pointer"
				>
					<BsChevronDoubleLeft className="text-neutral-500" />
				</button>
				<button
					onClick={ () => handlePageChange(currentPage - 1) }
					disabled={ currentPage === 1 }
					className="border border-neutral-300 p-2 rounded-md focus:outline-none shadow-sm hover:bg-neutral-200 cursor-pointer"
				>
					<BsChevronLeft className="text-neutral-500" />
				</button>
				{ [ ...Array(totalPages) ].map((_, index) => {
					const pageNumber = index + 1;
					if (pageNumber >= startPage && pageNumber <= endPage) {
						return (
							<button
								key={ pageNumber }
								onClick={ () => handlePageChange(pageNumber) }
								className={ `border border-neutral-300 p-2 rounded-md focus:outline-none cursor-pointer shadow-sm ${ pageNumber === currentPage
										? "bg-neutral-300 text-neutral-700"
										: "text-neutral-500 hover:bg-neutral-200"
									}` }
							>
								{ pageNumber }
							</button>
						);
					}
					return null;
				}) }
				<button
					onClick={ () => handlePageChange(currentPage + 1) }
					disabled={ currentPage === totalPages }
					className="border border-neutral-300 p-2 rounded-md focus:outline-none shadow-sm hover:bg-neutral-200 cursor-pointer"
				>
					<BsChevronRight className="text-neutral-500" />
				</button>
				<button
					onClick={ handleLastPage }
					disabled={ currentPage === totalPages }
					className="border border-neutral-300 p-2 rounded-md focus:outline-none shadow-sm hover:bg-neutral-200 cursor-pointer"
				>
					<BsChevronDoubleRight className="text-neutral-500" />
				</button>
			</div>

			<div className="ml-4">
				<label className="text-neutral-500 text-xs mr-2">Rows per page:</label>
				<select
					value={ rowsPerPage }
					onChange={ (e) => handleRowsPerPageChange(Number(e.target.value)) }
					className="border border-neutral-300 rounded-md shadow-sm text-xs p-2 focus:outline-none"
				>
					{ rowsPerPageOptions.map((option) => (
						<option key={ option } value={ option }>
							{ option }
						</option>
					)) }
				</select>
			</div>
		</div>
	);
}

export default TablePagination;