import { TableHeader } from "./TableHeader";
import { TablePagination } from "../TablePagination";
import { SearchFilter } from "../SearchFilter";
import { TableRow } from "./TableRow";
import { useTable, createRowRecord } from "../util";

/**
 * This Table version is used to display data in a record-like format.
 * Each row in the table displays both the header and the value of the field,
 * breaking lines at the maxFieldsPerRow value.  Functionally, it is the same
 * as the normal Table component, with visual differences.
 */
export function Table ({
	data,
	headers: overrideHeaders,
	transformer,
	asRecords = false,
	rowsPerPageOptions = [ 1, 2, 3, 5, 10, 25, 50, 100, 1000 ],
	rowsPerPageDefaultIndex = 0,
	onRowSelect = () => { },
	maxFieldsPerRow = 6,
	maxPageButtons = 7,
	showHeader = true,
	showFilter = true,
	showFooter = true,
	color = "neutral",
}) {
	const {
		headers,
		currentPage,
		rowsPerPage,
		searchTerm,
		sortConfig,
		filteredRows,
		indexOfFirstRow,
		indexOfLastRow,
		currentRows,
		totalPages,
		handleSearch,
		handlePageChange,
		handleFirstPage,
		handleLastPage,
		handleRowsPerPageChange,
		setSortConfig
	} = useTable({ data, headers: overrideHeaders, asRecords, transformer, rowsPerPageOptions, rowsPerPageDefaultIndex });

	return (
		<>
			{
				showFilter && (
					<SearchFilter searchTerm={ searchTerm } handleSearch={ handleSearch } />
				)
			}
			{
				showHeader && <TableHeader
					headers={ headers }
					sortConfig={ sortConfig }
					onSortChange={ setSortConfig }
					maxFieldsPerRow={ maxFieldsPerRow }
					color={ color }
				/>
			}

			{ currentRows.map((row, index) => (
				<TableRow
					key={ index }
					index={ index }
					row={ row }
					headers={ headers }
					maxFieldsPerRow={ maxFieldsPerRow }
					handleRowDoubleClick={ (row, index) => {
						if (!asRecords) return;	// Currently only for record-like tables

						let filteredRecord = createRowRecord(row, headers);
						let dbRecord = {};

						for (const rec of data) {
							let { record } = filteredRecord;

							let match = Object.keys(record).every(key => {
								return rec[ key ] === record[ key ];
							});

							if (match) {
								dbRecord = rec;
								break;
							}
						}

						onRowSelect({
							...filteredRecord,
							dbRecord,
						});
					} }
					color={ color }
				/>
			)) }

			{
				showFooter && (
					<TablePagination
						currentPage={ currentPage }
						totalPages={ totalPages }
						handlePageChange={ handlePageChange }
						handleFirstPage={ handleFirstPage }
						handleLastPage={ handleLastPage }
						rowsPerPage={ rowsPerPage }
						handleRowsPerPageChange={ handleRowsPerPageChange }
						rowsPerPageOptions={ rowsPerPageOptions }
						indexOfFirstRow={ indexOfFirstRow }
						indexOfLastRow={ indexOfLastRow }
						filteredRowsLength={ filteredRows.length }
						maxPageButtons={ maxPageButtons }
					/>
				)
			}
		</>
	);
}

export default Table;