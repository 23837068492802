import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { NavBar } from "./modules/auth/components/NavBar";

import { NetworkBusProvider } from "./modules/message/network/react/NetworkBusContext";
import { MessageBusProvider } from "./modules/message/react/MessageBusContext";
import { Provider as StateProvider, useStore, SqlRequestMessage } from "./modules/state/store";

import { SelectBuyer } from "./components/buyer/SelectBuyer";
import { ManageBuyer } from "./components/buyer/ManageBuyer";

import { Logout } from "./modules/auth/components/Logout";
import FetchStaticFile from "./modules/html/components/FetchStaticFile";
import NoPermission from "./modules/auth/components/NoPermission";
import { AllMaps } from "./components/leads/AllMaps";
import { CoverageMap } from "./components/leads/CoverageMap";

import BuyerSolveDelta from "./modules/insights/components/BuyerSolveDelta";
import BuyerSellerSolveDelta from "./modules/insights/components/BuyerSellerSolveDelta";

export const isValidAccount = account => account?.idTokenClaims?.groups?.some(gid => gid === process.env.REACT_APP_AZURE_AD_GROUP_ID) ?? false;

export const Guardian = () => {
	const { state, dispatch, sync } = useStore();
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();

	useEffect(() => {
		if (!state.user && activeAccount) {
			dispatch({
				type: "setUser",
				payload: activeAccount,
			});

			/* A manipulator to cache the current "database variables" into the store, as a key-value object */
			sync({
				type: "dbVariables.sync",
				payload: SqlRequestMessage("query", {
					query: `SELECT * FROM DecisionEngine.vwVariableValue`,
				}),
			}, false, data => {
				const next = {};

				let firstResultSet = data?.payload;
				if (Array.isArray(firstResultSet)) {
					for (const row of firstResultSet) {
						next[ row.VariableName ] = row.Value;
					}
				} else {
					return data;
				}

				return next;
			});
		}
	}, [ activeAccount ]);

	return (
		<>
			<UnauthenticatedTemplate>
				<div className="flex flex-col justify-center items-center" style={ { height: "calc(90vh)" } }>
					<div className="text-3xl font-bold">Please login to continue</div>
				</div>

				<Routes>
					<Route path="*" element={ <Navigate to="/" replace /> } />
				</Routes>
			</UnauthenticatedTemplate>

			<AuthenticatedTemplate>
				{
					isValidAccount(activeAccount) ? (
						<Routes>
							<Route path="/buyers/:buyer" element={ <ManageBuyer /> } />
							<Route path="/buyers" element={ <SelectBuyer /> } />
							<Route path="/logout" element={ <Logout /> } />
							<Route path="/maps/:make" element={ <CoverageMap /> } />
							<Route path="/maps" element={ <AllMaps /> } />
							<Route path="/insights/bsd" element={ <BuyerSolveDelta /> } />
							<Route path="/insights/bssd" element={ <BuyerSellerSolveDelta /> } />
							<Route path="/public/*" element={ <FetchStaticFile /> } />
							<Route path="*" element={ <Navigate to="/buyers" replace /> } />
						</Routes>
					) : (
						<NoPermission />
					)
				}
			</AuthenticatedTemplate>
		</>
	);
};

export function App ({ msal }) {
	return (
		<MsalProvider instance={ msal }>
			<NetworkBusProvider>
				<MessageBusProvider>
					<StateProvider>
						<NavBar />

						<Guardian />
					</StateProvider>
				</MessageBusProvider>
			</NetworkBusProvider>
		</MsalProvider>
	);
};

export default App;