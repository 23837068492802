import { Banner } from "../Banner";
import { Table } from "../table/normal/Table";

export const CompareBuyerSellerSummary = ({ current, next }) => {
	if (!current || !next) {
		return <Banner color="rose" header="Error" body="A required Buyer data object was missing or empty" />;
	}

	let state = current?.state?.payload?.[ 0 ];
	let nextState = next?.state?.payload?.[ 0 ];
	let comparison = [];

	const constrainValue = (key, value) => {
		if (typeof value === "number" || /^\d+$/.test(value)) {
			if (key.includes("Volume")) {
				value = value.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
			} else if (key.includes("Rate")) {
				value = (value * 100).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %";
			}
		}

		return value;
	};

	if (!state || !nextState) {
		return null;
	}

	/* Create a kvp object using the .SellerDTXID as the key, and the entire object as the value */
	let stateObj = Object.fromEntries(state.map((entry) => [ entry.SellerDTXID, entry ])),
		nextStateObj = Object.fromEntries(nextState.map((entry) => [ entry.SellerDTXID, entry ]));

	for(const key in nextStateObj) {
		let currentEntry = stateObj[ key ],
			nextEntry = nextStateObj[ key ];

		if(!currentEntry || !nextEntry) {
			continue;
		}

		let row = [
			[ "DTXID", currentEntry?.SellerDTXID ],
			[ "Seller", currentEntry?.SellerName ],
			[ "CurrentVolume", currentEntry?.LeadsTakenSoFar ],
			[ "CurrentExpectedTotalVolume", currentEntry?.TotalPostsToTakeAfterReturns ],
			[ "ProjectedExpectedTotalVolume", nextEntry?.TotalPostsToTakeAfterReturns ],
			[ "ProjectedVolumeDifference", nextEntry?.TotalPostsToTakeAfterReturns - currentEntry?.TotalPostsToTakeAfterReturns ],
			[ "CurrentCloseRate", currentEntry?.CurrentProjectedCloseRate ],
			[ "ProjectedCloseRate", nextEntry?.CurrentProjectedCloseRate ],
			[ "ProjectedCloseRateDifference", nextEntry?.CurrentProjectedCloseRate - currentEntry?.CurrentProjectedCloseRate ],
		];

		row = Object.fromEntries(row.map(([ key, value ]) => {
			return [ key, constrainValue(key, value) ];
		}));

		comparison.push(row);
	}

	comparison.sort((a, b) => {
		return b.ProjectedVolumeDifference - a.ProjectedVolumeDifference;
	});

	console.log(state)
	console.log(nextState)
	console.log(comparison)

	return (
		<>
			<Table
				data={ comparison }
				asRecords={ true }
				color="neutral"
				transformer={ (row, headers) => {
					for (let i = 0; i < headers.length; i++) {
						const header = headers[ i ];

						let value = constrainValue(header, row[ i ]);
						if (header.includes("Difference")) {
							if (parseFloat(row[ i ]) > 0) {
								row[ i ] = (
									<span className="text-green-500">+{ value }</span>
								);
							} else if (parseFloat(row[ i ]) < 0) {
								row[ i ] = (
									<span className="text-red-500">{ value }</span>
								);
							} else {
								row[ i ] = (
									<span className="text-neutral-500">{ value }</span>
								);
							}
						} else {
							row[ i ] = (
								<span className={ "text-neutral-500" }>{ value }</span>
							);
						}
					}

					return row;
				} }
			/>
		</>
	);
};

export default CompareBuyerSellerSummary;