import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../modules/state/store";
import { EnumActions } from "../../modules/message/EnumActions";
import { Loading } from "../utility/Loading";

/**
 * The purpose of this is to allow hard-linking to a buyer's page (e.g. /buyers/0000-2439)
 * and have meaningful content displayed when the page loads.  This is necessary
 * because everything is otherwise managed through the Context alone.
 */
export const RedirectHelper = () => {
	const navigate = useNavigate();
	const { buyer: DTXID } = useParams();

	const { state, dispatch, sync } = useStore();
	const { selected, buyers: { status: buyerStatus, state: buyerState } } = state;

	useEffect(() => {
		sync({
			type: "buyers.sync",
			payload: {
				$timeout: 1000 * 30,
				type: EnumActions.Packet.Data.BUYER_REQUEST,
				payload: {},
			},
		});
	}, []);

	useEffect(() => {
		let buyers = buyerState?.payload ?? [];

		if (buyerStatus === "complete") {
			const dbRecord = buyers.find((record) => record.DTXID === DTXID);

			dispatch({
				type: "setSelected",
				payload: dbRecord,
			});
		}
	}, [ buyerStatus ]);

	if (selected) {
		// Redirect to the buyer's page
		setTimeout(() => navigate(`/buyers/${ selected.DTXID }`), 0);
		return null;
	}

	return (
		<>
			<Loading
				scale={ 1.5 }
				text="Loading..."
			/>
		</>
	);
};

export default RedirectHelper;