import { v4 as uuid } from "uuid";

export const EnumMatchType = {
	FIRST: "FIRST",
	ALL: "ALL",
};

export const EnumMode = {
	REALTIME: "REALTIME",
	BATCH: "BATCH",
};

export const EnumOverflowMode = {
	IGNORE: "IGNORE",
	AUTOFLUSH: "AUTOFLUSH",
};

/**
 * The MessageBus supports both realtime and batched message dispatching.
 * Additionally, it supports both first-match and all-match message routing.
 * If you batch your messages, you can also specify an overflow mode and
 * max batch size.
 * For events, you can also specify a filter function to only receive
 * messages that match your criteria.
 * Additionally, events can be namespaced.  As such, you can "traverse" into
 * a namespace to target particular events, or you can invoke entire namespaces
 * by sending an event with the namespace as the type.  When using namespaces,
 * you should use dot notation to separate the namespace from the event name.
 * As such, be aware that "." in types is reserved for namespace traversal,
 * and will be treated as such.
 *
 * Example:
 *
 * const bus = new MessageBus();
 *
 * bus.subscribe("foo.bar", (message) => console.log(message));
 * bus.subscribe("foo.bar.baz", (message) => console.log(message));
 *
 * bus.dispatch({ type: "foo.bar", data: "Hello, World!" });
 * bus.dispatch({ type: "foo.bar.baz", data: "Hello, World 2!" });
 */
export class MessageBus {
	constructor({ listeners = {}, mode = EnumMode.REALTIME, matchType = EnumMatchType.FIRST, maxBatchSize = 10, overflowMode = EnumOverflowMode.AUTOFLUSH } = {}) {
		this.id = uuid();
		this.listeners = { default: [] };
		this.queue = [];

		this.config = {
			mode: mode,
			matchType: matchType,
			maxBatchSize: maxBatchSize,
			overflowMode: overflowMode,
		};

		Object.keys(listeners).forEach((type) => {
			this.subscribeMany(type, listeners[ type ]);
		});
	}

	shapeEventTree (payload, currentLevel = this.listeners) {
		for (const [ key, value ] of Object.entries(payload)) {
			if (typeof value === "object" && Object.keys(value).length === 0) {
				// If value is an empty object, create an empty namespace if not already exists
				if (!currentLevel[ key ]) {
					currentLevel[ key ] = {};
				}
			} else if (typeof value === "object" && Object.keys(value).length > 0) {
				// If value is a populated object, recurse through it
				if (!currentLevel[ key ]) {
					currentLevel[ key ] = {};
				}
				this.shapeEventTree(value, currentLevel[ key ]);
			} else if (Array.isArray(value)) {
				// If value is an array, create a listeners array if not already a namespace
				if (!currentLevel[ key ]) {
					currentLevel[ key ] = { listeners: [] };
				} else {
					if (typeof currentLevel[ key ] === "object" && !currentLevel[ key ].listeners) {
						// Abort if key is already a namespace
						console.error(`Cannot set "${ key }" as an event, it's already a namespace.`);
					}
				}
			}
		}
	}

	subscribe (type, listener, filter = () => true) {
		if (Array.isArray(listener)) {
			listener.forEach((l) => this.subscribe(type, l, filter));
			return;
		} else if (typeof listener !== "function") {
			return false;
		}

		const typeParts = type.split(".");
		let currentLevel = this.listeners;

		// Navigate to the appropriate level in the listeners object
		for (let i = 0; i < typeParts.length; i++) {
			const part = typeParts[ i ];
			if (!currentLevel[ part ]) {
				currentLevel[ part ] = {};
			}
			currentLevel = currentLevel[ part ];

			// If we are not at the last part and there are already listeners at this level,
			// then it means this part is already being used as a namespaced event.
			if (i < typeParts.length - 1 && Array.isArray(currentLevel.listeners)) {
				console.error("Cannot subscribe to namespace that already contains events.");
				return false;
			}
		}

		// If we are at the last part and this part has any namespaced events, prevent direct subscribing.
		if (Object.keys(currentLevel).length > 0) {
			console.error("Cannot subscribe to namespace that already contains namespaced events.");
			return false;
		}

		if (!Array.isArray(currentLevel.listeners)) {
			currentLevel.listeners = [];
		}
		currentLevel.listeners.push({ listener, filter });
	}

	subscribeMany (type, listeners, filter = () => true) {
		if (!Array.isArray(listeners)) {
			listeners = [ listeners ];
		}

		listeners.forEach((listener) => this.subscribe(type, listener, filter));
	}

	unsubscribe (type, listener) {
		const typeParts = type.split(".");
		let currentLevel = this.listeners;
		for (const part of typeParts) {
			if (!currentLevel[ part ]) return;
			currentLevel = currentLevel[ part ];
		}

		if (currentLevel.listeners) {
			currentLevel.listeners = currentLevel.listeners.filter((l) => l.listener !== listener);
		}
	}

	unsubscribeMany (type, listeners = []) {
		if (!Array.isArray(listeners)) {
			listeners = [ listeners ];
		}

		listeners.forEach((listener) => this.unsubscribe(type, listener));
	}

	route (message, listeners = this.listeners, typeOverride = null, callback) {
		let didMatch = false;
		const typeParts = (typeOverride || message.type).split(".");
		let currentLevel = listeners;

		for (const part of typeParts) {
			if (!currentLevel[ part ]) return;
			currentLevel = currentLevel[ part ];
		}

		if (Array.isArray(currentLevel.listeners)) {
			currentLevel.listeners.forEach(({ listener, filter }) => {
				if (filter(message)) {
					const result = listener(message);
					if (callback) {
						if (result instanceof Promise) {
							result.then((...args) => callback(...args));
						} else {
							callback(result);
						}
					}
					didMatch = true;
				}
			});
		} else {
			Object.keys(currentLevel).forEach((subType) => {
				const newType = typeOverride ? `${ typeOverride }.${ subType }` : subType;
				this.route(message, currentLevel, newType, callback);
			});
		}

		if (!didMatch || this.config.matchType === EnumMatchType.ALL) {
			this.listeners.default.forEach(({ listener, filter }) => {
				if (filter(message)) {
					const result = listener(message);
					if (callback) {
						if (result instanceof Promise) {
							result.then((...args) => callback(...args));
						} else {
							callback(result);
						}
					}
				}
			});
		}
	}
	process (callback) {
		while (this.queue.length > 0) {
			const message = this.queue.shift();
			this.route(message, void 0, void 0, callback);
		}
	}
	dispatch (message, callback) {
		if (this.config.mode === EnumMode.BATCH) {
			if (this.queue.length < this.config.maxBatchSize || this.config.overflowMode === EnumOverflowMode.AUTOFLUSH) {
				this.queue.push(message);
				if (this.queue.length >= this.config.maxBatchSize && this.config.overflowMode === EnumOverflowMode.AUTOFLUSH) {
					this.process(callback);
				}
			}
		} else {
			this.route(message, void 0, void 0, callback);
		}
	}

	async routeAsync (message, listeners = this.listeners, typeOverride = null, callback) {
		let didMatch = false;
		const typeParts = (typeOverride || message.type).split(".");
		let currentLevel = listeners;

		for (const part of typeParts) {
			if (!currentLevel[ part ]) {
				if(typeOverride) {
					return;
				}
			} else {
				currentLevel = currentLevel[ part ];
			}
		}

		if (Array.isArray(currentLevel.listeners)) {
			for (const { listener, filter } of currentLevel.listeners) {
				if (filter(message)) {
					const result = await listener(message);
					if (callback) {
						if (result instanceof Promise) {
							result.then((...args) => callback(...args));
						} else {
							callback(result);
						}
					}
					didMatch = true;
				}
			}
		} else {
			for (const subType of Object.keys(currentLevel)) {
				const newType = typeOverride ? `${ typeOverride }.${ subType }` : subType;
				await this.routeAsync(message, currentLevel, newType);
			}
		}

		if (!didMatch || this.config.matchType === EnumMatchType.ALL) {
			for (const { listener, filter } of this.listeners.default) {
				if (filter(message)) {
					const result = await listener(message);
					if (callback) {
						if (result instanceof Promise) {
							result.then((...args) => callback(...args));
						} else {
							callback(result);
						}
					}
				}
			}
		}
	}
	async processAsync (callback) {
		while (this.queue.length > 0) {
			const message = this.queue.shift();
			await this.routeAsync(message, void 0, void 0, callback);
		}
	}
	async dispatchAsync (message, callback) {
		if (this.config.mode === EnumMode.BATCH) {
			if (this.queue.length < this.config.maxBatchSize || this.config.overflowMode === EnumOverflowMode.AUTOFLUSH) {
				this.queue.push(message);
				if (this.queue.length >= this.config.maxBatchSize && this.config.overflowMode === EnumOverflowMode.AUTOFLUSH) {
					await this.processAsync(callback);
				}
			}
		} else {
			await this.routeAsync(message, void 0, void 0, callback);
		}
	}
}

export default MessageBus;