import { useEffect } from "react";
import { useStore, SqlRequestMessage } from "../../modules/state/store";
import { EnumActions } from "../../modules/message/EnumActions";

import { RedirectHelper } from "./RedirectHelper";

import { BuyerBanner } from "./BuyerBanner";
import { TargetBanner } from "./TargetBanner";
import { FullSolveForm } from "./FullSolveForm";
import { FullSolveResultDialog } from "./FullSolveResultDialog";
import { BuyerSummary } from "./BuyerSummary";
import { CompareSolves } from "./CompareSolves";
import Banner from "../Banner";

export function ManageBuyer () {
	const { state, dispatch, sync } = useStore();
	const {
		selected,
		solve: {
			defaultParams: {
				status: defaultParamsStatus,
				state: defaultParamsState,
			},
			decisionEngine: {
				status: decisionEngineStatus,
				state: decisionEngineState,
			},
			deploymentStore: {
				status: deploymentStoreStatus,
				state: deploymentStoreState,
			},
		},
		dbVariables: {
			state: dbVariablesState,
		},
	} = state;


	const defaultParams = defaultParamsState?.payload?.[ 0 ];
	const decisionEngineResult = decisionEngineState?.payload;
	const formData = state?.form?.data;
	const singleBuyerSolveID = decisionEngineResult?.[ 0 ]?.SolveID;

	useEffect(() => {
		if (!selected) return;

		sync({
			type: "solve.defaultParams.sync",
			payload: SqlRequestMessage("tvf", {
				name: "XRef.tvfGetSolveParameters",
				params: [
					selected.DTXID,
					`${ (new Date()).toISOString().slice(0, 7) }-01`,	// current month, as YYYY-MM-01
				],
			}),
		});
		sync({
			type: "summary.current.buyer.sync",
			payload: SqlRequestMessage("sproc", {
				sproc: "DecisionEngine.spBuyerRecommendationSummary",
				params: {
					SolveID: selected?.LastDeployedSolveID,
				},
			}),
		});
		sync({
			type: "summary.current.buyerSeller.sync",
			payload: SqlRequestMessage("sproc", {
				$timeout: 120000,
				sproc: "DecisionEngine.spBuyerSellerRecommendationSummary",
				params: {
					SolveID: selected?.LastDeployedSolveID,
				},
			}),
		});
	}, [ selected ]);

	useEffect(() => {
		if (defaultParamsStatus === "complete") {
			dispatch({
				type: "setFormData",
				payload: defaultParams,
			});

			sync({
				type: "summary.current.solutionSpace.sync",
				payload: {
					type: EnumActions.Packet.DecisionEngine.CHART_REQUEST,
					payload: {
						BlobDateTime: dbVariablesState?.ForecastBlobDateTimeUTC,
						Buyers: {
							[ selected.DTXID ]: {
								...defaultParams,
								MustTakeOverride: defaultParams.MustTakeOverride ? 1 : 0,
							},
						},

						//STUB
						// [ selected.DTXID ]: {
						// 	...defaultParams,
						// 	MustTakeOverride: defaultParams.MustTakeOverride ? 1 : 0,
						// },
					},
				},
			});
		}
	}, [ defaultParamsStatus ]);

	useEffect(() => {
		if (decisionEngineStatus === "complete") {
			sync({
				type: "summary.provisional.buyer.sync",
				payload: SqlRequestMessage("sproc", {
					sproc: "DecisionEngine.spBuyerRecommendationSummary",
					params: {
						SolveID: singleBuyerSolveID,
					},
				}),
			});
			sync({
				type: "summary.provisional.solutionSpace.sync",
				payload: {
					type: EnumActions.Packet.DecisionEngine.CHART_REQUEST,
					payload: {
						BlobDateTime: dbVariablesState?.ForecastBlobDateTimeUTC,
						Buyers: {
							[ selected.DTXID ]: {
								...formData,
								MustTakeOverride: formData.MustTakeOverride ? 1 : 0,
							},
						},

						//STUB
						// [ selected.DTXID ]: {
						// 	...formData,
						// 	MustTakeOverride: defaultParams.MustTakeOverride ? 1 : 0,
						// },
					},
				},
			});
			sync({
				type: "summary.provisional.buyerSeller.sync",
				payload: SqlRequestMessage("sproc", {
					sproc: "DecisionEngine.spBuyerSellerRecommendationSummary",
					params: {
						SolveID: singleBuyerSolveID,
					},
				}),
			});
		}
	}, [ decisionEngineStatus ]);
	useEffect(() => {
		if (deploymentStoreStatus === "complete") {
			//TODO: What should actually happen once you deploy (visually/redirect-wise)?
			dispatch({ type: "reset" });
		}
	}, [ deploymentStoreStatus ]);

	if (!selected) {
		// See note in component
		return <RedirectHelper />;
	}

	return (
		<>
			{
				(+dbVariablesState?.IsBackupBlob === 1) && <Banner
					color={ `rose` }
					header={ `Backup Blob Detected` }
					body={ `If this is unexpected, please escalate accordingly.` }
				/>
			}
			<div className="flex flex-col items-center justify-center p-2 text-neutral-700">
				<div className="flex flex-row w-full p-2 border border-solid rounded border-neutral-200 shadow">
					<BuyerBanner
						dispatch={ dispatch }
						selected={ selected }
					/>
				</div>

				<div className="flex flex-row w-full p-2 mt-2 items-center justify-center gap-4">
					<TargetBanner selected={ selected } />
				</div>

				<div className="flex flex-col w-full gap-2 mt-2 rounded">
					<FullSolveForm
						onSubmit={ data => {
							const user = `${ state.user.name } <${ state.user.username }>`;
							sync({
								type: "solve.decisionEngine.sync",
								payload: {
									type: EnumActions.Packet.DecisionEngine.FULLSOLVE_REQUEST,
									payload: {
										BlobDateTime: dbVariablesState?.ForecastBlobDateTimeUTC,
										Buyers: {
											[ selected.DTXID ]: {
												...data,
												MustTakeOverride: data.MustTakeOverride ? 1 : 0,
												user,
											},
										},

										//STUB
										// [ selected.DTXID ]: {
										// 	...data,
										// 	MustTakeOverride: data.MustTakeOverride ? 1 : 0,
										// 	user,
										// },
									},
								},
							});
						} }
					/>
					<FullSolveResultDialog
						status={ { decisionEngineStatus, deploymentStoreStatus } }
						state={ { decisionEngineResult, decisionEngineState, deploymentStoreState } }
						onDeploy={ (reason) => {
							sync({
								type: "solve.deploymentStore.sync",
								payload: SqlRequestMessage("sproc", {
									sproc: "DecisionEngine.spDeployProvisionalSolve",
									params: {
										SolveID: singleBuyerSolveID,
										DeployReason: reason,
									},
								}, 1000 * 60 * 5),	// 5 minutes, to give time for the full replication process
							});
						} }
						onAbandon={ () => {
							sync({
								type: "solve.deploymentStore.sync",
								payload: SqlRequestMessage("sproc", {
									sproc: "DecisionEngine.spAbandonProvisionalSolve",
									params: {
										SolveID: singleBuyerSolveID,
									},
								}),
							});
						} }
					/>
				</div>

				<div className="flex flex-col w-full gap-2 mt-4 rounded">
					{
						decisionEngineStatus === "complete" ? (
							<CompareSolves
								selected={ selected }
								solutionSpaces={ {
									current: state?.summary?.current?.solutionSpace,
									next: state?.summary?.provisional?.solutionSpace,
								} }
								current={ {
									formData,
									updateForm: (data) => dispatch({ type: "setFormData", payload: { ...formData, ...data } }),
									isProvisional: false,
									selected,
									solutionSpace: state?.summary?.current?.solutionSpace,
									buyer: state?.summary?.current?.buyer,
									buyerSeller: state?.summary?.current?.buyerSeller,
								} }
								next={ {
									formData,
									updateForm: (data) => dispatch({ type: "setFormData", payload: { ...formData, ...data } }),
									isProvisional: true,
									selected,
									solutionSpace: state?.summary?.provisional?.solutionSpace,
									buyer: state?.summary?.provisional?.buyer,
									buyerSeller: state?.summary?.provisional?.buyerSeller,
								} }
							/>
						) : (
							<BuyerSummary
								summary={ {
									formData,
									updateForm: (data) => dispatch({ type: "setFormData", payload: { ...formData, ...data } }),
									isProvisional: false,
									selected,
									solutionSpace: state?.summary?.current?.solutionSpace,
									buyer: state?.summary?.current?.buyer,
									buyerSeller: state?.summary?.current?.buyerSeller,
								} }
							/>

						)
					}
				</div>
			</div>
		</>
	);
};

export default ManageBuyer;