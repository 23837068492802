import { withSwitch } from "../../../util/Enumerator";

/**
 * Pre-canned enumerator for more standardized envelope types
 * for network communication.
 */
export const EnumEnvelopeType = withSwitch({
	REQUEST: "request",
	RESPONSE: "response",
	NOTIFICATION: "notification",
	ERROR: "error",
});