import { useRef } from "react";
import Plot from "react-plotly.js";

/**
 * More or less a wrapper around Plotly"s Plot component that forces
 * it to always be responsive, even if the config is changed.  If this
 * is not desirable, then use Plotly"s Plot component directly.
 */
export function ResponsivePlot (props) {
	const plotRef = useRef(null);
	const { layout, config, ...otherProps } = props;

	if (!props.data) {
		return (
			<div style={ { width: "100%", height: "100%" } }>
				<div className="text-center text-2xl text-neutral-400">No Data</div>
			</div>
		);
	}

	return (
		<Plot
			{ ...otherProps }
			ref={ plotRef }
			className="m-auto w-full h-full"
			onClick={ props?.onClick }
			useResizeHandler={ true }
			layout={ { ...layout, autosize: true, title: layout.title } }
			style={ { minWidth: 400, minHeight: 600 } }
			config={ { displayModeBar: true, ...(config || {}), responsive: true } }
		/>
	);
};

export default ResponsivePlot;