import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../modules/state/store";
import { EnumActions } from "../../modules/message/EnumActions";

import { Loading } from "../utility/Loading";
import { Table as TableGrid } from "../table/grid/Table";
import { AsyncStatus } from "../AsyncStatus";
import { DBErrorBanner } from "../DBErrorBanner";
import Banner from "../Banner";

export function SelectBuyer () {
	const navigate = useNavigate();
	const { state, sync, dispatch } = useStore();
	const { selected, buyers: { status: buyerStatus, state: buyerState }, dbVariables: { state: dbVariablesState } } = state;
	let buyers = buyerState?.payload ?? [];

	useEffect(() => {
		sync({
			type: "buyers.sync",
			payload: {
				$timeout: 30000,
				type: EnumActions.Packet.Data.BUYER_REQUEST,
				payload: {},
			},
		});
	}, []);

	useEffect(() => {
		if (selected?.DTXID) {
			navigate(`/buyers/${ selected.DTXID }`, {
				state: buyers.find((record) => record.DTXID === selected.DTXID),
			});
		}
	}, [ selected ]);

	return (
		<>
			{
				(+dbVariablesState?.IsBackupBlob === 1) && <Banner
					color={ `rose` }
					header={ `Backup Blob Detected` }
					body={ `If this is unexpected, please escalate accordingly.` }
				/>
			}
			<AsyncStatus
				status={ buyerStatus }
				onLoading={ () => (
					<div className="flex items-center justify-center min-h-screen">
						<Loading />
					</div>
				) }
				onError={ () => {
					return (
						<>
							<div className="flex flex-col items-center justify-center min-h-screen">
								<DBErrorBanner
									header="Error loading buyers"
									source={ buyerState }
								/>
							</div>
						</>
					);
				} }
				onComplete={ () => (
					<>
						<div className="m-2 flex flex-col text-neutral-700">
							<h3 className="text-2xl text-center mb-1 w-full select-none font-smallcaps">Manager Buyers</h3>
							<div className="flex flex-col items-center justify-center space-y-2">
								<TableGrid
									data={ buyers }
									headers={ [ "Buyer", "DTXID", "BuyerGroup", "WasModified", "FinalVolume", "FinalMarginPercent", "FinalCloseRate", "FinalMargin" ] }
									asRecords={ true }
									color="neutral"
									showHeader={ true }
									maxFieldsPerRow={ 4 }
									maxPageButtons={ 5 }
									rowsPerPageDefaultIndex={ 3 }
									onRowSelect={ ({ record, ...rest }) => {
										const dbRecord = buyers.find(buyer => buyer.DTXID === record.DTXID);
										dispatch({
											type: "setSelected",
											payload: dbRecord,
										});
									} }
									transformer={ (row, headers, dbRecords) => {
										const dbRecord = dbRecords.find(record => record.DTXID === row[ 1 ]);

										for (let i = 0; i < headers.length; i++) {
											const header = headers[ i ];

											if (header === "WasModified") {
												let value = dbRecord.WasModified ? "Yes" : "No";
												row[ i ] = (
													<span className={ dbRecord.WasModified ? "text-sky-500" : "text-neutral-700" }>{ value }</span>
												);
											} else if (header === "FinalVolume") {
												let value = dbRecord.FinalVolume?.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
												row[ i ] = (
													<>
														<span className={ dbRecord.IsVolumeMet ? "text-emerald-500" : "text-rose-500" }>{ value }</span>
														<span className="text-neutral-400">&nbsp;/&nbsp;{ dbRecord.MonthlyVolume?.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</span>
													</>
												);
											} else if (header === "FinalMarginPercent") {
												let value = (dbRecord.FinalMarginPercent * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
												row[ i ] = (
													<>
														<span className={ dbRecord.IsMarginPercentMet ? "text-emerald-500" : "text-rose-500" }>{ value } %</span>
														<span className="text-neutral-400">&nbsp;/&nbsp;{ (dbRecord.MonthlyMarginPercent * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
													</>
												);
											} else if (header === "FinalCloseRate") {
												let value = (dbRecord.FinalCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
												row[ i ] = (
													<>
														<span className={ dbRecord.IsCloseRateMet ? "text-emerald-500" : "text-rose-500" }>{ value } %</span>
														<span className="text-neutral-400">&nbsp;/&nbsp;{ (dbRecord.MonthlyCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
													</>
												);
											} else if (header === "FinalMargin") {
												let value = dbRecord.FinalMargin?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
												row[ i ] = (
													<div>$ { value }</div>
												);
											}
										}

										return row;
									} }
								/>
							</div>
						</div>
					</>
				) }
			/>
		</>
	);
};

export default SelectBuyer;