export function Button ({ icon, label, onClick, ...props }) {
	return (
		<button
			onClick={ onClick }
			{ ...props }
		>
			{ icon }
			<span>{ label }</span>
		</button>
	);
};

export default Button;