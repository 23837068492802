import React from "react";
import { isPOJO } from "./../util/util";
import { BsExclamationTriangle, BsInfoCircle } from "react-icons/bs";

export function Banner ({ color, header, body, children, ...props }) {
	let bodyText = isPOJO(body) && !Object.keys(body) ? JSON.stringify(body) : body;

	if (bodyText === "{}" && header === "Error") {
		bodyText = "An unspecified error has occurred.";
	}

	return (
		<div
			className={ `min-h-[5rem] mx-auto p-2 flex justify-center shadow items-center space-x-2 bg-${ color }-50 text-${ color }-500 border border-solid border-${ color }-200 rounded p-2 m-2` }
			{ ...props }
		>
			<div className="flex items-start px-4">
				{
					(color === "rose" || color === "red") ? (
						<BsExclamationTriangle className={ `text-${ color }-500 text-2xl` } />
					) : (
						<BsInfoCircle className={ `text-${ color }-500 text-2xl` } />
					)
				}
			</div>
			<div className="flex flex-col pr-4">
				{ header ? <div className={ `text-${ color }-700 text-center mr-2` }>{ header }</div> : null }
				{ bodyText ? <div className={ `text-${ color }-500 text-sm` }>{ bodyText }</div> : null }
				{ children }
			</div>
		</div>
	);
};

export default Banner;