export function NoPermission () {
	return (
		<div className="flex flex-col justify-center items-center" style={ { height: "calc(90vh)" } }>
			<img
				className="w-3/4 h-3/4"
				alt="Unauthorized Access"
				src="/assets/images/noauth.gif"
			/>
		</div>
	);
};

export default NoPermission;