import { EnumFieldType } from "./EnumFieldType.js";

export const handleErrorStyling = (element, isError) => {
	if (isError) {
		element?.classList?.add("border-red-500");
	} else {
		element?.classList?.remove("border-red-500");
	}
};

export const handleFieldValidation = (field, next) => {
	if (!next) return true;

	let value = next,
		valid = true;

	if (value.length > 0 || field?.validation?.required || field?.validation?.validator) {
		if (value === "") {
			handleErrorStyling(next, true);
			valid = false;
		} else {
			handleErrorStyling(next, false);
		}

		if (field?.validation?.validator) {
			const validator = field.validation.validator;
			if (validator && typeof validator === "function") {
				if (!validator(value, field)) {
					handleErrorStyling(next, true);
					valid = false;
				} else {
					handleErrorStyling(next, false);
				}
			}
		}

		if (field?.validation?.min != null && field.validation.min > value.length) {
			handleErrorStyling(next, true);
			valid = false;
		}

		if (field?.validation?.max != null && field.validation.max < value.length) {
			handleErrorStyling(next, true);
			valid = false;
		}
	}

	return valid;
};

export const handleSectionValidation = (section, e) => {
	let valid = true;
	section.elements.forEach((entry) => {
		if (entry.type === "section" && entry.elements) {
			valid = handleSectionValidation(entry, e) && valid;
		} else {
			valid = handleFieldValidation(entry, e) && valid;
		}
	});
	return valid;
};

/**
 * This will convert a Form Schema into a data object and seed it with `null` values.
 *
 * @param {Object} schema The Form Schema to convert
 * @param {Object} asObject Whether to return the data as a nested Object (`true` -- n-level depth) or a path-namespaced Object (`false` -- everything is root-level)
 * @param {Object} includeRoot Whether to include (`true`) or not (`false`) the root element in the data (i.e. the root FORM entry) -- affects root-level namespacing
 *
 * NOTE: Nested FORMs will *always* be included in the data, regardless of the value of `includeRoot`
 */
export function schemaToData (schema, { asObject = false, includeRoot = false } = {}) {
	const registry = {};

	const recurse = (element, path = [], isRoot = false) => {
		const { name, type, elements: subElements } = element;
		let newPath = [ ...path ];
		if (includeRoot || !isRoot) {
			newPath.push(name);
		}
		if (type !== EnumFieldType.SECTION && type !== EnumFieldType.FORM) {
			const key = asObject ? newPath[ newPath.length - 1 ] : newPath.join(".");
			let target = registry;
			if (asObject) {
				for (let i = 0; i < newPath.length - 1; i++) {
					if (!target[ newPath[ i ] ]) target[ newPath[ i ] ] = {};
					target = target[ newPath[ i ] ];
				}
			}

			let value = null;
			if (element.defaultValue !== undefined) {
				value = element.defaultValue;
			}
			if (element.value !== undefined) {
				value = element.value;
			}
			target[ key ] = value;
		}
		if (subElements) {
			subElements.forEach(subElement => recurse(subElement, newPath));
		}
	};

	recurse(schema, [], true);

	return registry;
};

export const schemaToFieldArray = (input) => {
	const results = [];
	if (Array.isArray(input)) {
		for (const field of input) {
			if (field?.type === EnumFieldType.SECTION) {
				results.push([ field, schemaToFieldArray(field.elements) ]);
			} else {
				results.push(field);
			}
		}
	} else if (input?.type === EnumFieldType.FORM) {
		results.push(...schemaToFieldArray(input.elements));
	}
	return results;
};

export const clone = (input = {}, seen = new Set()) => {
	// deep clone the input object, ignoring circular references through the `seen` Set
	if (input === null || typeof input !== "object") return input;
	if (seen.has(input)) return input;
	seen.add(input);

	const output = Array.isArray(input) ? [] : {};
	for (const key in input) {
		if (typeof input[ key ] === "object" && input[ key ] !== null) {
			output[ key ] = clone(input[ key ], seen);
		} else {
			output[ key ] = input[ key ];
		}
	}

	return output;
};

export const constrainValue = (field, nextValue) => {
	/* If there are numerical validation constraints, apply them */
	// NOTE: This is mostly to deal with REAL-FLOAT conversions, but applies more broadly, as well
	if (field.validation) {
		if (field.validation.min) {
			nextValue = Math.max(+nextValue, +field.validation.min);
		}
		if (field.validation.max) {
			nextValue = Math.min(+nextValue, +field.validation.max);
		}
		if (field.validation.step) {
			const precision = -Math.log10(+field.validation.step);
			nextValue = +nextValue?.toFixed(precision);
		}
	}

	return nextValue;
};

export const applyConstraints = (schema, data) => {
	const clonedData = clone(data);

	const constrainValue = (field) => {
		const key = field.name;

		if (key in clonedData) {
			let nextValue = clonedData[ key ];

			/* If there are numerical validation constraints, apply them */
			// NOTE: This is mostly to deal with REAL-FLOAT conversions, but applies more broadly, as well
			if (field.validation) {
				if (field.validation.min) {
					nextValue = Math.max(+nextValue, +field.validation.min);
				}
				if (field.validation.max) {
					nextValue = Math.min(+nextValue, +field.validation.max);
				}
				if (field.validation.step) {
					const precision = -Math.log10(+field.validation.step);
					nextValue = +nextValue?.toFixed(precision);
				}
			}

			return nextValue;
		}
	};

	for (const element of schema.elements) {
		if (element.type === "section") {
			for (const field of element.elements) {
				clonedData[ field.name ] = constrainValue(field);
			}
		} else {
			clonedData[ element.name ] = constrainValue(element);
		}
	}

	return clonedData;
};

export const updateDefaultFormValues = (schema, defaults) => {
	const clonedFullSolveSchema = clone(schema);

	const constrainValue = (field) => {
		const key = field.name;

		if (key in defaults) {
			let nextValue = defaults[ key ];

			/* If there are numerical validation constraints, apply them */
			// NOTE: This is mostly to deal with REAL-FLOAT conversions, but applies more broadly, as well
			if (field.validation) {
				if (field.validation.min) {
					nextValue = Math.max(+nextValue, +field.validation.min);
				}
				if (field.validation.max) {
					nextValue = Math.min(+nextValue, +field.validation.max);
				}
				if (field.validation.step) {
					const precision = -Math.log10(+field.validation.step);
					nextValue = +nextValue.toFixed(precision);
				}
			}

			return nextValue;
		}
	}

	for (const element of clonedFullSolveSchema.elements) {
		if (element.type === "section") {
			for (const field of element.elements) {
				field.value = constrainValue(field);
			}
		} else {
			element.value = constrainValue(element);
		}
	}

	return clonedFullSolveSchema;
};

export default {
	handleErrorStyling,
	handleFieldValidation,
	handleSectionValidation,
	schemaToData,
	schemaToFieldArray,
	clone,
	updateDefaultFormValues,
	applyConstraints,
	constrainValue,
};