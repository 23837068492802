export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
		authority: `https://login.microsoftonline.com/${ process.env.REACT_APP_AZURE_AD_TENANT_ID }`,
		redirectUri: "/",
		postLogoutRedirectUri: "/",
	},
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
	scopes: [ "User.Read" ]
};