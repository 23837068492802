import { validate } from "uuid";
import Banner from "./Banner";

export const DBErrorBanner = ({ header = "Database Error", source }) => {
	let errors = source?.precedingErrors?.map(({ originalError }) => originalError?.info?.message) ?? [];

	if (source.toString().startsWith(`TypeError: Failed to fetch`)) {
		errors = [ `A critical service is unresponsive.  Please escalate this issue.` ];
	}

	if (source?.originalError?.info?.message) {
		errors.push(source?.originalError?.info?.message);
	}

	if (source?.type?.endsWith("_ERROR")) {
		const { payload } = source;

		if (typeof payload === "string") {
			errors.push(payload);
		}
	}

	errors = errors.reverse();

	if (validate(source)) return <Banner color="rose" header="Error" body={
		<>
			<div>Request <span onClick={ e => {
				// attempt to copy the error to the clipboard
				try {
					navigator.clipboard.writeText(source);
				} catch (e) { }
			} }>{ source }</span> has timed out.</div>
		</>
	} />;

	if (!errors.length) return <Banner color="rose" header="Error" body={ <span>A external service error was thrown without additional detail.  Ensure Python is working correctly.</span> } />;

	return (
		<Banner
			color="rose"
			header={ header }
			body={ errors?.map((err, i) => <div key={ i }>{ err }</div>) }
		/>
	);
};

export default DBErrorBanner;