export function Card ({ title, children }) {
	return (
		<div className="flex flex-col justify-center items-center border border-solid rounded border-neutral-200 shadow p-2 px-4 min-w-[12rem]">
			<span className="text-neutral-500 font-bold">{ title }</span>
			<div className="flex flex-row">
				{ children }
			</div>
		</div>
	);
};

export function TargetBanner ({ selected }) {
	if (!selected) return null;

	return (
		<>
			<Card title="Final Volume">
				<span className={ selected.IsVolumeMet ? "text-emerald-500" : "text-rose-500" }>{ selected.FinalVolume?.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</span>
				<span className="text-neutral-400">&nbsp;/&nbsp;{ selected.MonthlyVolume?.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</span>
			</Card>

			<Card title="Final Margin Percent">
				<span className={ selected.IsMarginPercentMet ? "text-emerald-500" : "text-rose-500" }>{ (selected.FinalMarginPercent * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
				<span className="text-neutral-400">&nbsp;/&nbsp;{ (selected.MonthlyMarginPercent * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
			</Card>

			<Card title="Final Close Rate">
				<span className={ selected.IsCloseRateMet ? "text-emerald-500" : "text-rose-500" }>{ (selected.FinalCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
				<span className="text-neutral-400">&nbsp;/&nbsp;{ (selected.MonthlyCloseRate * 100)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %</span>
			</Card>

			<Card title="Final Margin">
				<span>$ { selected.FinalMargin?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</span>
			</Card>
		</>
	)
};

export default TargetBanner;