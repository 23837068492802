import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { msalInstance } from "./modules/auth/main";

import App from "./App";

import "./assets/css/main.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<App msal={ msalInstance } />
	</BrowserRouter>
);