import React, { useMemo } from "react";

import { FormField } from "./FormField";
import { FormSection } from "./FormSection";

import EnumFieldType from "../lib/EnumFieldType";
import { handleFieldValidation } from "../lib/util";

export function Form ({
	children,
	schema,
	data,
	gridCols,
	renderFormField = (fieldProps) => <FormField { ...fieldProps } />,
	renderFormSection = (sectionProps, children) => <FormSection { ...sectionProps }>{ children }</FormSection>,
	onUpdate,
	onValidation,
}) {
	const validation = ({ field, path, next }) => {
		return handleFieldValidation(field, next);
	};

	const update = ({ next, path, field }) => {
		const { type } = field;
		let value = next;

		if (type === EnumFieldType.NUMBER) {
			value = parseFloat(next);
		} else if (type === EnumFieldType.BOOLEAN) {
			value = !!next;
		}

		if (value !== data[ path ]) {
			const validationResult = validation({ field, path, next: value });
			if (onValidation) {
				onValidation({ isValid: validationResult, field, path, next: value, current: data[ path ], data });
			}
			if (validationResult) {
				onUpdate({
					...data,
					[ path ]: value,
				});
			}
		}
	};

	const renderField = (field, key, path = "") => {
		const fullPath = path ? `${ path }.${ field.name }` : field.name;

		if (field.hidden) return null;

		if (field?.type === EnumFieldType.SECTION) {
			return renderFormSection(
				{
					key,
					field,
					path: fullPath,
					state: data[ fullPath ],
					gridCols
				},
				renderFields(field.elements, fullPath)
			);
		} else {
			return renderFormField(
				{
					key,
					field,
					path: fullPath,
					state: data[ fullPath ],		// React nonsense about nulls as input values
					next: (next) => update({
						path: fullPath,
						field,
						next,
						current: data[ fullPath ]
					}),
					gridCols
				}
			);
		}
	};

	const renderFields = (fields, path = '') => {
		return fields.map((field, index) => renderField(field, index, path));
	};

	const renderedSchema = useMemo(() => {
		if (schema?.type === EnumFieldType.FORM) {
			return renderFields(schema.elements);
		}
		return renderField(schema, 0);
	}, [ schema, data ]);

	return (
		<div
			className="m-2 p-2 border border-solid border-neutral-200 shadow-md rounded"
			style={ {
				display: "grid",
				gridTemplateColumns: `repeat(${ gridCols }, 1fr)`,
				gridGap: "0.5rem"
			} }
		>
			{ renderedSchema }
			{ children }
		</div>
	);
};

export default Form;