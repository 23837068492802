import React from "react";
import { parseHeader } from "../util";

export function TableHeader ({ headers, sortConfig, onSortChange, maxFieldsPerRow, color }) {
	const requestSort = (key) => {
		let direction = "asc";
		if (sortConfig.key === key && sortConfig.direction === "asc") {
			direction = "desc";
		}
		onSortChange({ key, direction });
	};

	return (
		<div className={ `bg-${ color }-100 text-xs flex items-stretch select-none` }>
			{ headers.map((header, index) => (
				<React.Fragment key={ index }>
					<div
						key={ index }
						className={ `py-2 px-4 font-semibold text-${ color }-500 cursor-pointer text-center hover:bg-${ color }-200 flex items-center justify-center` }
						onClick={ () => requestSort(header) }
						title={ `Sort asc/desc by ${ header }` }
					>
						<div>
							{ parseHeader(header) }
							{ sortConfig.key === header && (
								<span>{ sortConfig.direction === "asc" ? " ▲" : " ▼" }</span>
							) }
						</div>
					</div>
					{/* Render a pipe character before every maxFieldsPerRow header */ }
					{ (index + 1) % maxFieldsPerRow === 0 && index !== headers.length - 1 && (
						<div className={ `py-2 px-4 text-xs font-thin text-${ color }-500 text-center flex items-center justify-center` }>|</div>
					) }
				</React.Fragment>
			)) }
		</div>
	);
};

export default TableHeader;