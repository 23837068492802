import { v4 as uuid } from "uuid";

/**
 * Primarily used for sending messages between modules
 * and systems within the application (e.g. local).
 */
export function Message ({ type, payload, ...rest } = {}) {
	const msg = {
		id: uuid(),
		type,
		payload,
		timestamp: Date.now(),
		...rest,
	};

	return msg;
};

export default Message;