import React, { useEffect } from "react";
import { Radio } from "../Radio";

export function FieldRadio ({ state, field, next, ...rest }) {
	const { name, attr, options } = field;
	const [ selectedValue, setSelectedValue ] = React.useState(state ?? attr?.defaultValue);
	const inputValue = selectedValue ?? "";

	const handleChange = (e) => {
		const value = e.target.value;
		setSelectedValue(value);
	};

	useEffect(() => {
		if (selectedValue == null) return;
		next(selectedValue);
	}, [ selectedValue ]);

	return (
		<div className="flex flex-row gap-2 select-none">
			{ options.map((option, index) => {
				const value = typeof option === "object" ? option.value : option;
				const label = typeof option === "object" ? option.label : option;
				const checked = selectedValue == value;  // sic

				return (
					<label key={ index } className="inline-flex items-center cursor-pointer">
						<Radio
							name={ name }
							value={ value }
							onChange={ handleChange }
							checked={ checked }
							{ ...rest }
						/>
						<span className="ml-2 text-center">{ label }</span>
					</label>
				);
			}) }
			<input
				type="hidden"
				name={ name }
				value={ inputValue }
			/>
		</div>
	);
};

export default FieldRadio;