import { useEffect } from "react";
import { createMachine, assign } from "xstate";
import { useMachine } from "@xstate/react";

export const createAsyncMachine = (initialContext = {}) => {
	return createMachine({
		predictableActionArguments: true,
		initial: "default",
		context: {
			data: null,
			error: null,
			...initialContext
		},
		states: {
			default: {
				on: {
					LOAD: "loading",
				},
			},
			loading: {
				on: {
					SUCCESS: {
						target: "complete",
						actions: assign({
							data: (context, event) => event.data,
						}),
					},
					ERROR: {
						target: "error",
						actions: assign({
							error: (context, event) => event.error,
						}),
					},
				},
			},
			complete: {
				on: {
					LOAD: "loading",
				},
			},
			error: {
				on: {
					LOAD: "loading",
				},
			},
		},
	});
};

export function useAsyncMachine (machine, fetchFunction) {
	const [ current, send ] = useMachine(machine, {
		services: {
			fetchFunction,
		},
	});

	const sync = async (fetcher = fetchFunction, events = {}) => {
		const { load = "LOAD", success = "SUCCESS", error = "ERROR" } = events;

		send(load);

		fetcher
		.then((data) => {
				send(success, { data });
			})
			.catch((err) => {
				send(error, { error: err });
			});
	};

	useEffect(() => {
		sync(fetchFunction);
	}, []);

	return {
		error: current.context.error,
		data: current.context.data,
		state: current.value,
		sync,
	};
};

export default {
	createAsyncMachine,
	useAsyncMachine,
};