import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { BsFiletypeXlsx } from "react-icons/bs";

import { Table } from "../../../components/table/normal/Table";
import Loading from "../../../components/utility/Loading";

/**
 * Download"s the current BuyerSummary and BuyerSellerSummary as an Excel file.
 */
const DownloadButton = ({ records }) => {
	const handleDownload = () => {
		const wb = XLSX.utils.book_new();
		const ws_bsd = XLSX.utils.json_to_sheet(records);
		XLSX.utils.book_append_sheet(wb, ws_bsd, `SolveDeltas`);
		XLSX.writeFile(wb, `BuyerSolveDeltas-${ (new Date()).toISOString().slice(0, 10) }.xlsx`);
	};

	return (
		<button
			className="flex items-center"
			onClick={ handleDownload }
			title={ `Download Buyer Solve Deltas` }
		>
			<BsFiletypeXlsx
				className="text-emerald-600 hover:text-emerald-700"
				size={ 24 }
			/>
		</button>
	);
};

export function BuyerSolveDelta ({ }) {
	const [ records, setRecords ] = useState([]);

	useEffect(() => {
		fetch(`${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/insights/bd9377d6-d59a-4353-866f-730d931e8042`)
			.then(res => res.json())
			.then(data => setRecords(data))
			.catch(err => console.error(err));
	}, []);

	return (
		<div className="flex flex-col">
			<div className="p-2">
				{
					records.length === 0 ? (
						<Loading />
					) : (
						<>
							<div className="flex justify-between items-center w-full flex-col text-neutral-700">
								<div className="flex justify-between items-center w-full">
									<div className="text-3xl mb-2 font-bold w-full text-center">(Current - Previous) Solve Differences</div>
									{
										<DownloadButton records={ records } />
									}
								</div>
								<div className="text-center w-full text-sm text-neutral-600">
									This data maps the differences between the current and previous Buyer Summary data.
								</div>
							</div>
							<Table
								data={ records }
								asRecords={ true }
							/>
						</>
					)
				}
			</div>
		</div>
	);
};

export default BuyerSolveDelta;