import React from "react";
import { Listbox, Transition } from "@headlessui/react";

export function FieldDropdown ({ state, field, path, next, className, ...props }) {
	const { name, options, attr, validation } = field;
	const value = state ? options.find(option => option.value === state) : null;

	const handleChange = (option) => {
		next(option.value);
	};

	return (
		<Listbox value={ value } onChange={ handleChange }>
			{ ({ open }) => (
				<>
					<div
						className={ `relative mt-2 ${ className ? className : "focus:outline-none focus:border-sky-300 shadow-sm flex-1" }` }
						style={ { zIndex: open ? 10 : 1 } }
					>
						<Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border border-neutral-300 cursor-default focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-sky-200">
							<span className="block truncate">
								{ value ? value.label : <span className="text-neutral-400">{ attr?.placeholder || "Select an option..." }</span> }
							</span>
							<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<svg className="w-5 h-5 text-neutral-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
									<path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
								</svg>
							</span>
						</Listbox.Button>
						<Transition show={ open }>
							{ (ref) => (
								<Listbox.Options ref={ ref } className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
									{ options.map((option, index) => (
										<Listbox.Option
											key={ index }
											value={ option }
											className={ ({ active }) => `${ active ? "text-sky-700 bg-sky-100" : "text-neutral-700" } cursor-pointer select-none relative py-2 pl-10 pr-4` }
										>
											{ option.label }
										</Listbox.Option>
									)) }
								</Listbox.Options>
							) }
						</Transition>
					</div>
					<input
						type="hidden"
						id={ name }
						name={ name }
						value={ value ? value.value : "" }
						required={ validation?.required }
						readOnly={ attr?.readOnly }
						{ ...props }
					/>
				</>
			) }
		</Listbox>
	);
}

export default FieldDropdown;