/**
 * Create a "switch/router" statement for the given Enumerator.
 * @ param {string} type - The enum key (e.g. "REQUEST", "RESPONSE", "ERROR").
 * @ param {object} map - The "evaluation map" when the type matches.
 * @ param {?function} map.default - The default function to call when the type does not match.
 * @ param {?function} map[ type ] - The function to call when the type matches.
 * @ param {...any} args - The arguments to pass to the map function.
 * @ returns {any} The result of the map function.
 *
 * The values in the map can be either a function or a value.  If
 * the value is a function, it will be called with the arguments
 * passed to the $witch function.  If the value is not a function,
 * it will be returned as-is.
 *
 * Example:
 *
 * const Enum = withSwitch({
 * 	REQUEST: "request",
 * 	RESPONSE: "response",
 * 	NOTIFICATION: "notification",
 * 	ERROR: "error",
 * });
 *
 * const result = Enum.$witch("request", {
 * 	[ Enum.REQUEST ]: (arg1, arg2) => {
 * 		return arg1 + arg2;
 * 	},
 * 	[ Enum.RESPONSE ]: "response",
 * 	[ Enum.NOTIFICATION ]: "notification",
 * 	[ Enum.ERROR ]: "error",
 * }, 1, 2);
 *
 * console.log(result); // 3
 */
export const withSwitch = (_enum) => {
	const fn = (type, map, ...args) => {
		if (!Object.keys(_enum).includes(type.toUpperCase())) {
			if (typeof map.default === "function") {
				return map.default(...args);
			}

			return;
		}

		const value = map[ type ];
		if (typeof value === "function") {
			return value(...args);
		} else {
			return value;
		}
	};

	_enum.$witch = fn;
	return _enum;
};

/**
 * Create a bijection enumeration object from an array of strings.
 *
 * Example:
 *
 * const Enum = fromArray([
 * 	"REQUEST",
 * 	"RESPONSE",
 * 	"NOTIFICATION",
 * 	"ERROR",
 * ]);
 *
 * console.log(Enum.REQUEST); // "REQUEST"
 */
export function fromArray (arr, toUpperCase = true) {
	const obj = {};
	for (let i = 0; i < arr.length; i++) {
		const key = arr[ i ];

		if (toUpperCase) {
			obj[ key.toUpperCase() ] = key;
		} else {
			obj[ key ] = key;
		}
	}

	return obj;
};

export default {
	$witch: withSwitch,
	fromArray,
};