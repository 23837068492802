import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

export function FieldToggle ({ state, field, path, next, ...props }) {
	const { name, label, attr } = field;
	const [ checked, setChecked ] = useState(state ?? attr?.defaultValue);
	const inputValue = checked ?? "";

	useEffect(() => {
		if (checked == null) return;
		next(checked);
	}, [ checked ]);

	// Fix for initial state not being set
	useEffect(() => {
		if(state !== checked) {
			setChecked(state);
		}
	}, [ state ]);

	return (
		<>
			<Switch
				checked={ inputValue }
				onChange={ setChecked }
				className={ `${ checked ? "bg-sky-500" : "bg-neutral-200" } relative inline-flex h-6 w-11 items-center rounded-full` }
			>
				<span className="sr-only">{ label }</span>
				<span
					className={ `${ checked ? "translate-x-6" : "translate-x-1" } inline-block h-4 w-4 transform rounded-full bg-white transition` }
				/>
			</Switch>

			<input
				type="hidden" // Include a hidden input to store the checkbox value
				id={ field.id }
				name={ name }
				value={ inputValue }
				{ ...(field?.attr ?? {}) }
				{ ...props }
			/>
		</>
	);
};

export default FieldToggle;