import { parseHeader } from "../util";

export function TableHeader ({ headers, sortColumn, sortConfig, color }) {
	return (
		<thead>
			<tr className={ `bg-${ color }-200 text-xs` }>
				{ headers.map((header, index) => (
					<th
						key={ index }
						className={ `py-2 px-4 font-semibold hover:bg-${ color }-300 cursor-pointer` }
						onClick={ () => sortColumn(header) }
					>
						<div className={ `flex items-center text-${ color }-600 space-x-1 focus:outline-none` }>
							{ parseHeader(header) }
							{ sortConfig.key === header &&
								(sortConfig.direction === "asc" ? " ▲" : " ▼") }
						</div>
					</th>
				)) }
			</tr>
		</thead>
	);
}

export default TableHeader;