import { useEffect, useState } from "react";
import { BsArrowCounterclockwise, BsCloudUpload } from "react-icons/bs";

import { schemaToData } from "../../modules/form/lib/util";
import { useStore } from "../../modules/state/store";
import { Form } from "../../modules/form/components/Form";
import { FormField } from "../../modules/form/components/FormField";
import { FormSection } from "../../modules/form/components/FormSection";
import { InfoTooltip } from "../../modules/form/components/InfoTooltip";
import { constrainValue } from "../../modules/form/lib/util";

import fullSolveSchema from "../../data/forms/FullSolve.json";

export const fullSolveDataDefaults = () => schemaToData(fullSolveSchema);

export function FullSolveForm ({ onSubmit }) {
	const [ formHasChanged, setFormHasChanged ] = useState(false);
	const { state, dispatch } = useStore();
	const {
		form: {
			schema: formSchema,
			data: formData,
		},
		solve: {
			defaultParams: {
				state: defaultParamsState,
			},
		},
	} = state;

	const [ cachedFormData, setCachedFormData ] = useState(formData);
	const defaultParams = defaultParamsState?.payload?.[ 0 ];

	useEffect(() => {
		if (JSON.stringify(cachedFormData) !== JSON.stringify(formData)) {
			setCachedFormData(formData);

			if ("ForecastMonth" in cachedFormData) {
				setFormHasChanged(true);
			}
		}
	}, [ formData ]);

	return (
		<div className="flex flex-col items-center justify-center w-full">
			<div className="w-full gap-2">
				<Form
					gridCols={ 3 }
					schema={ formSchema }
					data={ formData }
					renderFormField={ ({ field, state, path, update, gridCols, key, ...props }) => {
						let nv = formData?.[ path ],
							ov = constrainValue(field, defaultParams?.[ path ]);

						let hasChanged = nv !== ov;

						return (
							<div className="flex flex-row" key={ key }>
								<div className={ `flex flex-row ${ gridCols === 1 ? "w-1/6" : "w-1/3" }` }>
									<div className="pr-1 my-auto flex-wrap">
										<InfoTooltip field={ field } changeFlag={ hasChanged } />
									</div>
									<span className="my-auto text-xs font-normal">{ field.label }</span>
								</div>

								<div className={ `flex ${ gridCols === 1 ? "w-5/6" : "w-2/3" } my-2` }>
									<FormField
										field={ field }
										state={ state }
										path={ path }
										update={ update }
										{ ...props }
									/>
								</div>
							</div>
						);
					} }
					renderFormSection={ (sectionProps, children) => (
						<FormSection { ...sectionProps }>
							{ children }
						</FormSection>
					) }
					onUpdate={ data => {
						dispatch({
							type: "setFormData",
							payload: data,
						});

						if (!formHasChanged) {
							setFormHasChanged(true);
						}
					} }
				>
					<div className="flex w-full col-span-full">
						<button
							className="p-2 px-4 my-2 bg-white hover:bg-rose-100 hover:border-rose-300 hover:text-rose-500 text-neutral-500 border border-solid border-primary border-neutral-300 rounded shadow-sm"
							title={ `Push to Prod` }
							onClick={ e => {
								//FIXME: Remove once DE2.0 fully replaces current DE
								const userConfirmed = window.confirm("WARNING: Are you sure you want to push to TranDB?  This will *overwrite* br.RuleConfiguration data!");
								if (userConfirmed) {
									window.open(`${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/etl/pipeline/f5cb6cf1-a603-42b5-bd7c-427b52b2cf0b`, "_blank");
									window.open(`${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/etl/status/f5cb6cf1-a603-42b5-bd7c-427b52b2cf0b`, "_blank");
								} else {
									console.warn("User canceled request to push to TranDB.");
								}
							} }
						>
							<BsCloudUpload />
						</button>
						<button
							className="flex-grow border border-solid border-primary rounded p-2 m-2 bg-white hover:bg-sky-100 hover:border-sky-300 hover:text-sky-500 text-neutral-500 border-neutral-300 shadow-sm"
							onClick={ e => {
								if (formHasChanged) {
									onSubmit(formData);
								} else {
									alert("No changes detected. Please make changes and try again.");
								}
							} }
						>
							Run Full Solve
						</button>
						<button
							className="p-2 px-4 my-2 bg-white hover:bg-amber-100 hover:border-amber-300 hover:text-amber-500 text-neutral-500 border border-solid border-primary border-neutral-300 rounded shadow-sm"
							title={ `Reset to default values` }
							onClick={ e => {
								dispatch({
									type: "setFormData",
									payload: defaultParams,
								});
							} }
						>
							<BsArrowCounterclockwise />
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default FullSolveForm;