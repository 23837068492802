import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { BsBarChartLine, BsGlobeAmericas, BsHouseFill, BsListUl, BsTable } from "react-icons/bs";
import { useStore } from "../../state/store";
import { Menu } from "@headlessui/react";

export const NavBar = () => {
	const { dispatch } = useStore();
	const navigate = useNavigate();
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();

	return (
		<div className="mb-2 border-b border-b-neutral-200 shadow bg-neutral-100 py-2">
			<div className="mx-auto flex justify-between items-center text-sm">
				<div className="ml-2 my-auto select-none flex items-center">
					{
						activeAccount ? (
							<>
								<BsHouseFill
									size={ 20 }
									className="cursor-pointer text-neutral-500 hover:text-sky-600"
									title="Go to Buyer Selection"
									onClick={ () => {
										dispatch({ type: "reset" });
										navigate("/buyers");
									} }
								/>
								<BsGlobeAmericas
									size={ 20 }
									className="ml-4 cursor-pointer text-neutral-500 hover:text-sky-600"
									title="Go to Coverage Maps"
									onClick={ () => navigate("/maps") }
								/>
								<Menu as="div" className="relative ml-4">
									<Menu.Button className="flex items-center text-neutral-500 hover:text-sky-600">
										<BsBarChartLine size={ 20 } />
									</Menu.Button>
									<Menu.Items className="absolute left-0 w-48 mt-2 bg-white divide-y divide-neutral-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
										<Menu.Item>
											{ ({ active }) => (
												<button
													className={ `${ active ? "bg-neutral-100" : ""
														} group flex rounded-md items-center w-full p-2 text-sm text-neutral-600 hover:text-sky-700` }
													onClick={ () => navigate("/insights/bsd") }
												>
													<BsListUl className="mr-2" size={ 24 } />
													<div className="flex items-center justify-center flex-col w-full p-2 group">
														<div className="w-full text-center text-sm font-bold mb-1 group-hover:text-sky-700 text-neutral-600">
															Buyer Solve Deltas
														</div>
														<div className="w-full text-left text-xs group-hover:text-sky-700 text-neutral-500">
															This data maps the differences between the current and previous Buyer Summary data.
														</div>
													</div>
												</button>
											) }
										</Menu.Item>
										<Menu.Item>
											{ ({ active }) => (
												<button
													className={ `${ active ? "bg-neutral-100" : ""
														} group flex rounded-md items-center w-full p-2 text-sm text-neutral-600 hover:text-sky-700` }
													onClick={ () => navigate("/insights/bssd") }
												>
													<BsTable className="mr-2" size={ 24 } />
													<div className="flex items-center justify-center flex-col w-full p-2 group">
														<div className="w-full text-center text-sm font-bold mb-1 group-hover:text-sky-700 text-neutral-600">
															Buyer-Seller Solve Deltas
														</div>
														<div className="w-full text-left text-xs group-hover:text-sky-700 text-neutral-500">
															This data maps the differences between the current and previous Buyer-Seller Summary data.
														</div>
													</div>
												</button>
											) }
										</Menu.Item>
									</Menu.Items>
								</Menu>
								<div className="ml-4">{ activeAccount?.name } ({ activeAccount?.username })</div>
							</>
						) : null
					}
				</div>
				<div className="mr-2">
					{ activeAccount ? (
						<button
							className="py-1 px-2 border border-neutral-300 rounded bg-neutral-100 hover:bg-rose-200 hover:border-rose-300 hover:text-rose-600"
							onClick={ () => instance.logout() }
						>
							Logout
						</button>
					) : (
						<button
							className="py-1 px-2 border border-neutral-300 rounded bg-neutral-100 hover:bg-sky-200 hover:border-sky-300 hover:text-sky-600"
							onClick={ () => instance.loginPopup() }
						>
							Login
						</button>
					) }
				</div>
			</div>
		</div>
	);
};

export default NavBar;