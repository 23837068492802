import { createContext, useContext, useState } from "react";
import { NetworkBus } from "../NetworkBus";

export const NetworkBusContext = createContext(null);

export const NetworkBusProvider = ({ children }) => {
	const [ networkBus ] = useState(new NetworkBus());

	return (
		<NetworkBusContext.Provider value={ networkBus }>
			{ children }
		</NetworkBusContext.Provider>
	);
};

export function useNetworkBus () {
	return useContext(NetworkBusContext);
};

export default useNetworkBus;