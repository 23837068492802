import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { TablePagination } from "../TablePagination";
import { SearchFilter } from "../SearchFilter";
import { useTable, createRowRecord } from "../util";

/**
 * This is a generic table component that can be used to display data in a table.
 * It can accept data as a "table array" or as a "record array".  See `useTable`
 * for more information on syntax.
 */
export function Table ({
	data,
	headers: overrideHeaders,
	transformer,
	asRecords = false,
	rowsPerPageOptions = [ 10, 25, 50, 100, 1000 ],
	rowsPerPageDefaultIndex = 0,
	onRowSelect = () => { },
	maxPageButtons = 7,
	showHeader = true,
	showFilter = true,
	showFooter = true,
	color = "neutral",
}) {
	const {
		headers,
		currentPage,
		rowsPerPage,
		searchTerm,
		sortConfig,
		filteredRows,
		indexOfFirstRow,
		indexOfLastRow,
		currentRows,
		totalPages,
		handleSearch,
		handlePageChange,
		handleFirstPage,
		handleLastPage,
		handleRowsPerPageChange,
		sortColumn
	} = useTable({ data, headers: overrideHeaders, asRecords, transformer, rowsPerPageOptions, rowsPerPageDefaultIndex });

	return (
		<>
			{
				showFilter && (
					<SearchFilter searchTerm={ searchTerm } handleSearch={ handleSearch } />
				)
			}

			<div className="w-full overflow-x-scroll">
				<table className={ `w-full border border-${ color }-300 text-${ color }-700 select-none` }>
					{
						showHeader && (
							<TableHeader
								headers={ headers }
								sortColumn={ sortColumn }
								sortConfig={ sortConfig }
								color={ color }
							/>
						)
					}
					<tbody className="text-sm cursor-pointer">
						{ currentRows.map((row, index) => (
							<TableRow
								key={ index }
								row={ row }
								index={ index }
								onDoubleClick={ e => onRowSelect(createRowRecord(row, headers)) }
								color={ color }
							/>
						)) }
					</tbody>
				</table>
			</div>

			{
				showFooter && (
					<TablePagination
						currentPage={ currentPage }
						totalPages={ totalPages }
						handlePageChange={ handlePageChange }
						handleFirstPage={ handleFirstPage }
						handleLastPage={ handleLastPage }
						rowsPerPage={ rowsPerPage }
						handleRowsPerPageChange={ handleRowsPerPageChange }
						rowsPerPageOptions={ [ 5, 10, 25 ] }
						indexOfFirstRow={ indexOfFirstRow }
						indexOfLastRow={ indexOfLastRow }
						filteredRowsLength={ filteredRows.length }
						maxPageButtons={ maxPageButtons }
					/>
				)
			}
		</>
	);
}

export default Table;