/**
 * This serializer is an enhanced version of JSON.stringify and JSON.parse.
 * It's major purpose is to allow for serialization of functions, regexes, and bigints,
 * as these are not natively supported; however, the general nature of Forms requires that
 * these are supported.  Using the default export from this module will provide you
 * with an API similar to JSON.
 *
 * NOTE: The use of base64 encoding is used to avoid the control characters that may
 * be present in functions and regexes (which are not allowed in JSON).  As such,
 * base-64 is used to provide environment-agnostic de/encoding.
 */

import base64 from "base-64";

export function stringify (obj, spacing) {
	const seen = new WeakSet();

	function encodeFunction (func) {
		return base64.encode(func.toString());
	}

	return JSON.stringify(obj, (key, value) => {
		if (typeof value === "function") {
			return "function:" + encodeFunction(value);
		}
		if (typeof value === "bigint") {
			return value.toString() + "n";
		}
		if (typeof value === "object" && value !== null) {
			if (seen.has(value)) {
				return {};
			}
			seen.add(value);
		}
		if (value instanceof RegExp) {
			return value.toString();
		}
		return value;
	}, spacing);
};

export function parse (serializedString) {
	function decodeFunction (encodedFunc) {
		return base64.decode(encodedFunc);
	}

	function reconstruct (value) {
		if (typeof value === "string") {
			if (value.startsWith("function:")) {
				const encodedFunc = value.slice(9);
				const funcStr = decodeFunction(encodedFunc);
				// Use caution with eval, as it can execute arbitrary code
				const func = eval(`(${ funcStr })`);
				return func;
			} else if (/^\/.*\/[gimsuy]*$/.test(value)) {
				const lastSlashIndex = value.lastIndexOf("/");
				const pattern = value.slice(1, lastSlashIndex);
				const flags = value.slice(lastSlashIndex + 1);
				return new RegExp(pattern, flags);
			} else if (typeof BigInt !== "undefined" && /^\d+n$/.test(value)) {
				return BigInt(value.slice(0, -1));
			}
		} else if (typeof value === "object" && value !== null) {
			for (const key in value) {
				value[ key ] = reconstruct(value[ key ]);
			}
		}
		return value;
	}

	const parsedObj = JSON.parse(serializedString);
	return reconstruct(parsedObj);
};

export default {
	stringify,
	parse,
};



/* Example Usage */

// const circularReference = {};
// circularReference.self = circularReference;

// const exampleObject = {
// 	string: "hello",
// 	number: 123,
// 	bigint: 123456789123456789123456789n,
// 	regex: /test/g,
// 	circularReference,
// 	array: [ 1, 2, /arrayRegex/, 123n ],
// 	func: function (a, b) {
// 		return a + b;
// 	},
// 	nestedObject: {
// 		innerString: "world",
// 		innerBigint: 987654321987654321n,
// 		innerFunction: function () {
// 			console.log("inside");
// 		},
// 	},
// };

// console.log(Serializer.stringify(exampleObject));

// // Usage Example
// const serializedString = Serializer.stringify(exampleObject);
// const reconstructedObject = Serializer.parse(serializedString);

// console.log(reconstructedObject);