import { useCallback } from "react";
import { createEnvelope, parseEnvelope } from "../../util";
import { useNetworkBus } from "./NetworkBusContext";
import Serializer from "../../../../util/Serializer";

export function useFetch (uri) {
	const NetworkBus = useNetworkBus();

	const request = useCallback((message = {}, timeout = 1000 * 60 * 5, { headers = {}, ...options } = {}) => {
		const envelope = createEnvelope(message);

		const responsePromise = new Promise((resolve, reject) => {
			NetworkBus.enqueue(envelope.id, resolve, reject, timeout);

			const sendRequest = async () => {
				try {
					const httpResponse = await fetch(uri, {
						method: "POST",
						...options,
						headers: {
							"Content-Type": "application/json",
							...headers,
						},
						body: Serializer.stringify(envelope),
					});

					if (!httpResponse.ok) {
						throw new Error("Network response was not ok");
					}

					const responseData = await httpResponse.json();
					const parsedEnvelope = parseEnvelope(responseData);

					console.warn(parsedEnvelope);

					NetworkBus.dequeue(parsedEnvelope);
				} catch (error) {
					reject(error);
				}
			};

			sendRequest();
		});

		return responsePromise;
	}, [ NetworkBus ]);

	return { request };
}

export default useFetch;