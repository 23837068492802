import { Envelope } from "./network/Envelope";
import Serializer from "../../util/Serializer";

export const createEnvelope = Envelope;

export const parseEnvelope = (data) => {
	let envelope;
	try {
		envelope = Serializer.parse(data);
	} catch (error) {
		console.error("Error parsing incoming message:", error);
		return null;
	}
	return envelope;
};

export default {
	createEnvelope,
	parseEnvelope,
};