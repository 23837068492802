import React from "react";

export function TableRow ({ row, index, onDoubleClick, color, ...props }) {
	return (
		<tr
			className={
				`cursor-pointer w-full text-${ color }-700 ` +
				(index % 2 === 0
					? `bg-white hover:bg-${ color }-100 transition-colors`
					: `bg-${ color }-50 hover:bg-${ color }-100 transition-colors`)
			}
			onDoubleClick={ e => onDoubleClick(row, e) }
			{ ...props }
		>
			{ row.map((cell, cellIndex) => (
				<td
					key={ cellIndex }
					className={ `py-2 px-4 border-b border-${ color }-300 whitespace-nowrap` }
				>
					{ cell }
				</td>
			)) }
		</tr>
	);
}

export default TableRow;