import { EnumEnvelopeType } from "./EnumEnvelopeType";

export class NetworkBus {
	constructor() {
		this.requests = {};
	}

	enqueue (requestId, resolve, reject, timeout) {
		const timer = setTimeout(() => {
			reject(requestId);
			delete this.requests[ requestId ];
		}, timeout);

		this.requests[ requestId ] = { resolve, reject, timer };
	}
	dequeue (envelope) {
		const { type, message, id, request } = envelope;
		const requestId = EnumEnvelopeType.$witch(type, {
			[ EnumEnvelopeType.RESPONSE ]: request,
			[ EnumEnvelopeType.REQUEST ]: id,
			[ EnumEnvelopeType.ERROR ]: request || id,
		});

		if (this.requests[ requestId ]) {
			const { resolve, reject, timer } = this.requests[ requestId ];

			clearTimeout(timer);

			if (type === EnumEnvelopeType.ERROR) {
				reject(message);
			} else {
				resolve(message);
			}

			delete this.requests[ requestId ];
		}
	}
}

export default NetworkBus;