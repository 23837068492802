import React from "react";
import { BsSearch, BsXCircle } from "react-icons/bs";

export function SearchFilter ({ searchTerm, handleSearch }) {
	return (
		<div className="relative flex items-center w-full">
			<input
				type="text"
				placeholder="Filter results..."
				value={ searchTerm }
				onChange={ handleSearch }
				className="border border-neutral-200 p-2 pl-10 pr-8 my-2 rounded-md focus:outline-none shadow w-full"
			/>
			{ searchTerm && (
				<div
					className="absolute right-2 text-neutral-300 cursor-pointer hover:text-neutral-400"
					title="Clear the search filter"
					onClick={ () => handleSearch({ target: { value: "" } }) }	// emulate a clearing "event"
				>
					<BsXCircle />
				</div>
			) }
			<div className="absolute left-2 text-neutral-300">
				<BsSearch />
			</div>
		</div>
	);
}

export default SearchFilter;