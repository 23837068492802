import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "../../modules/state/store";
import { AsyncStatus } from "../AsyncStatus";
import { EnumActions } from "../../modules/message/EnumActions";

export function AllMaps () {
	const { state, sync } = useStore();
	const {
		coverageMaps,
	} = state;

	useEffect(() => {
		sync({
			type: "coverageMaps.sync",
			payload: {
				type: EnumActions.Packet.Data.COVERAGE_MAP_REQUEST,
				payload: {},
			},
		});
	}, []);

	const makes = coverageMaps?.state?.payload?.map(map => map.split("_")[ 0 ]).sort();

	return (
		<>
			<AsyncStatus
				status={ coverageMaps.status }
				onDefault={ () => null }
				onLoading={ () => null }
				onComplete={ () => {
					return (
						<div className="grid grid-cols-5 gap-2 items-center justify-items-center mx-2">
							{
								makes.map((make, index) => {
									return (
										<Link
											key={ index }
											to={ `/maps/${ make }` }
											className="border border-solid border-neutral-200 rounded shadow hover:shadow-md hover:border-sky-300"
										>
											<img
												alt={ make }
												title={ make }
												src={ `/assets/images/makes/${ make }.png` }
												className="max-w-full h-auto"
											/>
										</Link>
									);
								})
							}
						</div>
					);
				} }
				onError={ () => null }
			/>
		</>
	)
};

export default AllMaps;