import { useRef, useState } from "react";
import { Loading } from "../utility/Loading";
import { Banner } from "../Banner";
import { DBErrorBanner } from "../DBErrorBanner";

export const FullSolveResultDialog = ({ status, state = {}, onDeploy, onAbandon }) => {
	const inputRef = useRef(null);
	const [ deployReason, setDeployReason ] = useState("");

	const { decisionEngineStatus, deploymentStoreStatus } = status;
	const { decisionEngineResult, decisionEngineState, deploymentStoreState } = state;

	if (decisionEngineStatus === "default" || !decisionEngineStatus) return null;
	if (decisionEngineStatus === "loading") return <Loading text="Running Full Solve..." scale={ 1.2 } />;
	if (decisionEngineStatus === "error" || decisionEngineState?.payload?.error || decisionEngineState?.payload?.[ 0 ]?.error) {
		return (
			<DBErrorBanner
				header="Full Solve Attempt Error"
				source={ decisionEngineState }
			/>
		);
	};

	if (deploymentStoreStatus === "loading") return <Loading text="Deploying Solve..." scale={ 1.2 } />;
	if (deploymentStoreStatus === "error") {
		return (
			<DBErrorBanner
				header="Full Solve Deployment Error"
				source={ deploymentStoreState }
			/>
		);
	};
	if (deploymentStoreStatus === "complete") return null;

	// NOTE: Because the FULLSOLVE_REQUEST returns an array of solves buyers, decisionEngineResult?.[ 0 ]?.RowsInserted is the number of rows inserted for the first buyer ONLY
	return (
		<div className="w-full px-2">
			<Banner color="emerald" header="Success" body={ `${ decisionEngineResult?.[ 0 ]?.RowsInserted } rows inserted` } />

			<input
				ref={ inputRef }
				type="input"
				className={ `w-full mb-2 p-2 border border-solid rounded bg-white shadow-sm ` + (deployReason.trim().length > 3 ? "border-neutral-300 text-neutral-500" : "placeholder-rose-300 text-rose-500 border-rose-300 focus:border-rose-400 focus:ring-rose-400 focus:outline-none focus:ring-1") }
				placeholder="Enter a deployment reason"
				value={ deployReason }
				onChange={ e => setDeployReason(e.target.value) }
			/>

			<div className="flex flex-row items-center justify-center w-full gap-2">
				<button
					className="basis-1/2 col-span-full border border-solid border-primary rounded p-2 bg-white hover:bg-emerald-100 hover:border-emerald-300 hover:text-emerald-500 text-neutral-500 border-neutral-300 shadow-sm"
					onClick={ e => {
						if (deployReason.trim().length > 3) {
							onDeploy(deployReason.trim());
						} else {
							alert("You must enter a deployment reason");
							inputRef?.current?.focus();
						}
					} }
				>
					Deploy Solve
				</button>
				<button
					className="basis-1/2 col-span-full border border-solid border-primary rounded p-2 bg-white hover:bg-rose-100 hover:border-rose-300 hover:text-rose-500 text-neutral-500 border-neutral-300 shadow-sm"
					onClick={ e => onAbandon() }
				>
					Abandon Solve
				</button>
			</div>
		</div>
	);
};

export default FullSolveResultDialog;