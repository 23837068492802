import React, { createContext, useContext, useState } from "react";
import { MessageBus } from "../MessageBus";

export const MessageBusContext = createContext(null);

export const MessageBusProvider = ({ children }) => {
	const [ messageBus ] = useState(new MessageBus());

	return (
		<MessageBusContext.Provider value={ messageBus }>
			{ children }
		</MessageBusContext.Provider>
	);
};

export const useMessageBusContext = () => {
	return useContext(MessageBusContext);
};