import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

import { Button } from "../utility/Button";

export function BuyerBanner ({ dispatch, selected }) {
	const navigate = useNavigate();

	if (!selected) return null;

	const { Buyer: buyer, DTXID: dtxid, BuyerGroup: group } = selected;

	return (
		<>
			<Button
				className="inline-flex my-auto items-center space-x-2 px-4 py-2 bg-white hover:bg-sky-100 hover:border-sky-300 hover:text-sky-500 text-neutral-500 rounded border border-neutral-300 shadow-sm"
				icon={ <BsArrowLeft /> }
				label="Return to Search"
				onClick={ () => {
					dispatch({ type: "reset" });
					navigate("/buyers");
				} }
			/>
			<div className="flex flex-col items-center justify-center w-5/6">
				<div className="text-2xl font-bold font-smallcaps text-center text-neutral-700">{ buyer.charAt(0).toUpperCase() + buyer.slice(1) }</div>
				<div className="text-sm font-thin text-neutral-500">
					<span className="font-mono">{ dtxid }</span>&nbsp;|&nbsp;<span className="">{ group }</span>
				</div>
			</div>
		</>
	)
};

export default BuyerBanner;