import { interpret } from "xstate";
import React from "react";
import { createAsyncMachine } from "./AsyncMachine";
import { useFetch } from "../message/network/react/useFetch";

import FullSolveSchema from "../../data/forms/FullSolve.json";
import { schemaToData, applyConstraints } from "../form/lib/util";

import { EnumActions } from "../message/EnumActions";

export const EnumStatus = {
	DEFAULT: "default",
	LOADING: "loading",
	COMPLETE: "complete",
	ERROR: "error",
};

export const Helpers = {
	buildAsyncSlice: ({ ...rest } = {}) => ({
		status: EnumStatus.DEFAULT,
		state: {},
		service: interpret(createAsyncMachine()).start(),
		...rest,
	}),
	handleErrors: (error) => {
		return error;
	},
	createFSMReducer: (path) => {
		const keys = path.split('.');

		return function innerReducer (state, fsmState, service, index = 0) {
			if (index === keys.length - 1) {
				return {
					...state,
					[ keys[ index ] ]: {
						...state[ keys[ index ] ],
						status: fsmState.value,
						state: fsmState.context.data ?? Helpers.handleErrors(fsmState.context.error),
						service,
					},
				};
			}

			return {
				...state,
				[ keys[ index ] ]: innerReducer(state[ keys[ index ] ], fsmState, service, index + 1),
			};
		};
	},
};

export const State = () => ({
	solve: {
		defaultParams: Helpers.buildAsyncSlice(),
		decisionEngine: Helpers.buildAsyncSlice(),
		deploymentStore: Helpers.buildAsyncSlice(),
	},
	summary: {
		current: {
			buyer: Helpers.buildAsyncSlice({ state: [] }),
			buyerSeller: Helpers.buildAsyncSlice({ state: [] }),
			solutionSpace: Helpers.buildAsyncSlice(),
		},
		provisional: {
			buyer: Helpers.buildAsyncSlice({ state: [] }),
			buyerSeller: Helpers.buildAsyncSlice({ state: [] }),
			solutionSpace: Helpers.buildAsyncSlice(),
		},
		comparison: {},
	},
	buyers: Helpers.buildAsyncSlice(),
	selected: null,
	form: {
		schema: FullSolveSchema,
		data: schemaToData(FullSolveSchema),
	},
	dbVariables: Helpers.buildAsyncSlice(),	// cf. DecisionEngine.VariableDefinition / DecisionEngine.VariableValue
	user: null,
	coverageMaps: Helpers.buildAsyncSlice({ state: [] }),
});

export const Reducers = {
	reset: () => State(),
	resetSlice: (state, slice) => {
		// slice should be a string that represents the path to the slice
		// e.g. "solve.defaultParams"
		const keys = slice.split('.');
		const reducer = (state, index = 0) => {
			if (index === keys.length - 1) {
				return {
					...state,
					[ keys[ index ] ]: Helpers.buildAsyncSlice(),
				};
			}

			return {
				...state,
				[ keys[ index ] ]: reducer(state[ keys[ index ] ], index + 1),
			};
		};

		return reducer(state);
	},
	setUser: (state, account) => ({
		...state,
		user: account,
	}),
	setSelected: (state, selected) => ({
		...state,
		selected,
	}),
	setFormSchema: (state, schema) => ({
		...state,
		form: {
			...state.form,
			schema,
		},
	}),
	setFormData: (state, data) => {
		return {
			...state,
			form: {
				...state.form,
				data: applyConstraints(state.form.schema, data),
			},
		}
	},
	setSolveInput: (state, input = {}) => ({
		...state,
		solve: {
			...state.solve,
			input,
		},
	}),

	"dbVariables.sync": Helpers.createFSMReducer("dbVariables"),
	"buyers.sync": Helpers.createFSMReducer("buyers"),
	"coverageMaps.sync": Helpers.createFSMReducer("coverageMaps"),
	"solve.defaultParams.sync": Helpers.createFSMReducer("solve.defaultParams"),
	"solve.decisionEngine.sync": Helpers.createFSMReducer("solve.decisionEngine"),
	"solve.deploymentStore.sync": Helpers.createFSMReducer("solve.deploymentStore"),
	"summary.current.buyer.sync": Helpers.createFSMReducer("summary.current.buyer"),
	"summary.current.buyerSeller.sync": Helpers.createFSMReducer("summary.current.buyerSeller"),
	"summary.current.solutionSpace.sync": Helpers.createFSMReducer("summary.current.solutionSpace"),
	"summary.provisional.buyer.sync": Helpers.createFSMReducer("summary.provisional.buyer"),
	"summary.provisional.buyerSeller.sync": Helpers.createFSMReducer("summary.provisional.buyerSeller"),
	"summary.provisional.solutionSpace.sync": Helpers.createFSMReducer("summary.provisional.solutionSpace"),
};

export const Provider = ({ children }) => {
	const { request } = useFetch(`${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/ipc`);
	const [ state, setState ] = React.useState(State());

	const bindListener = (type, service) => service.onTransition((fsmState) => {
		setState(prevState => Reducers[ type ](prevState, fsmState, service));
	});

	const dispatch = (action) => {
		const { type, payload } = action;

		if (!Reducers[ type ]) return;

		setState(prevState => Reducers[ type ](prevState, payload));
	};
	const sync = (action, allowReset = false, transformer) => {
		const { type, payload } = action;

		if (!Reducers[ type ]) return;

		const keys = type.split('.').slice(0, -1);
		const stateEntry = keys.reduce((acc, key) => acc[ key ], state);

		const service = stateEntry?.service;

		if (!service) return;
		if (stateEntry?.status === "complete" && !allowReset) return;

		service.send("LOAD");

		bindListener(type, service);

		request(payload ?? {}, payload?.$timeout)
			.then((data) => {
				let txData = transformer ? transformer(data) : data;
				console.log(txData)
				service.send("SUCCESS", { data: txData });
			})
			.catch((err) => {
				console.log(err)
				service.send("ERROR", { error: err });
			})
			.finally(() => {
				// setState(prevState => Reducers[ type ](prevState, service?.state, service));
			})
	};

	return (
		<AsyncMachineContext.Provider value={ { state, dispatch, sync, request } }>
			{ children }
		</AsyncMachineContext.Provider>
	);
};


export const AsyncMachineContext = React.createContext();
export const useStore = () => React.useContext(AsyncMachineContext);

export const SqlRequestMessage = (type, config = {}, $timeout = 1000 * 60 * 5) => {
	return {
		$timeout,
		type: EnumActions.Packet.Data.QUERY_REQUEST,
		payload: {
			source: "mssql",
			config: {
				type,
				...config,
			},
		},
	};
};