export const EnumActions = {
	// Internal messages
	Message: {
		Form: {
			// Begin form submission pipeline
			SUBMIT: "FORM_SUBMIT",
		},
	},
	// External messages
	Packet: {
		Data: {
			// Coverage Map requests
			COVERAGE_MAP_REQUEST: "COVERAGE_MAP_REQUEST",
			COVERAGE_MAP_RESPONSE: "COVERAGE_MAP_RESPONSE",
			COVERAGE_MAP_ERROR: "COVERAGE_MAP_ERROR",
			// Get the data for the buyer
			BUYER_REQUEST: "DATA_BUYER_REQUEST",
			BUYER_RESPONSE: "DATA_BUYER_RESPONSE",
			BUYER_ERROR: "DATA_BUYER_ERROR",
			// Get the data for the buyer
			QUERY_REQUEST: "DATA_QUERY_REQUEST",
			QUERY_RESPONSE: "DATA_QUERY_RESPONSE",
			QUERY_ERROR: "DATA_QUERY_ERROR",
		},
		Form: {
			// Submit the form data
			SUBMIT_REQUEST: "FORM_SUBMIT_REQUEST",
			SUBMIT_RESPONSE: "FORM_SUBMIT_RESPONSE",
			SUBMIT_ERROR: "FORM_SUBMIT_ERROR",
			// Get the form schema (e.g. sproc)
			SCHEMA_REQUEST: "FORM_SCHEMA_REQUEST",
			SCHEMA_RESPONSE: "FORM_SCHEMA_RESPONSE",
			SCHEMA_ERROR: "FORM_SCHEMA_ERROR",
		},
		DecisionEngine: {
			// Get the current decision engine state for the buyer
			FULLSOLVE_REQUEST: "DE_FULLSOLVE_REQUEST",
			FULLSOLVE_RESPONSE: "DE_FULLSOLVE_RESPONSE",
			FULLSOLVE_ERROR: "DE_FULLSOLVE_ERROR",
			// Get the PlotlyJS JSON data for the chart
			CHART_REQUEST: "DE_SOLUTIONSPACE_JSON_REQUEST",
			CHART_RESPONSE: "DE_SOLUTIONSPACE_JSON_RESPONSE",
			CHART_ERROR: "DE_SOLUTIONSPACE_JSON_ERROR",
		},
	},
};

export default EnumActions;