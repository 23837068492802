import React from "react";

export function FormSection ({ field, children, path, state, gridCols, ...props }) {
	return (
		<div
			className={ `col-span-full border border-solid rounded p-4 shadow` }
			style={ {
				display: "grid",
				gridTemplateColumns: `repeat(${ gridCols }, 1fr)`,
				gridGap: "0.5rem"
			} }
			{ ...props }
		>
			{ children }
		</div>
	);
};

export default FormSection;