import React from "react";
import { Popover } from "@headlessui/react";
import { BsInfoCircle } from "react-icons/bs";

import Serializer from "../../../util/Serializer";

export function InfoTooltip ({ field, changeFlag = false }) {
	const renderValidationData = () => {
		if (!field) return null;

		// Extract the keys from the validation object
		const validationKeys = Object.keys(field.validation || {});

		return (
			<div className="w-full border border-solid rounded shadow select-none whitespace-nowrap text-sm">
				<div className="flex flex-col">
					{
						field.name && (
							<div className="border-b hover:bg-neutral-50 flex">
								<div className="p-2 font-light italic flex-1">
									name
								</div>
								<div className="p-2 flex-3">
									{ field.name }
								</div>
							</div>
						)
					}
					{
						field.type && (
							<div className="border-b hover:bg-neutral-50 flex">
								<div className="p-2 font-light italic flex-1">
									type
								</div>
								<div className="p-2 flex-3">
									{ field.type }
								</div>
							</div>
						)
					}
					{
						validationKeys.map((key) => field.validation[ key ] !== void 0 && (
							<div key={ key } className="border-b hover:bg-neutral-50 flex">
								<div className="p-2 font-light italic flex-1">{ key }</div>
								<div className="p-2 flex-3">{ Serializer.stringify(field.validation[ key ]).replaceAll(`"`, ``) }</div>
							</div>
						))
					}
					{
						field.description && (
							<div className="border-b hover:bg-neutral-50 flex">
								<div className="p-2 font-light italic flex-4" colSpan={ 2 }>
									{ field.description }
								</div>
							</div>
						)
					}
				</div>
			</div>
		);
	};

	return (
		<div className="m-1">
			<Popover>
				{ ({ open }) => (
					<>
						<Popover.Button>
							<BsInfoCircle className={`inline-block ml-2 text-${ changeFlag ? "sky" : "neutral" }-400 align-middle hover:text-${ changeFlag ? "sky" : "neutral" }-500 active:text-${ changeFlag ? "sky" : "neutral" }-600`} />
						</Popover.Button>
						<Popover.Panel className={ `absolute z-10 bg-white border border-neutral-300 p-1 rounded-md shadow-md ${ open ? "" : "hidden" }` }>
							{ renderValidationData() }
						</Popover.Panel>
					</>
				) }
			</Popover>
		</div>
	);
};

export default InfoTooltip;
