import React from "react";
import { parseHeader } from "../util";

export function TableRow ({ row, index, headers, maxFieldsPerRow, handleRowDoubleClick, color, ...props }) {
	return (
		<div
			className={
				`cursor-pointer border border-solid border-${ color }-200 rounded m-2 p-2 shadow text-center select-none w-full ` +
				(index % 2 === 0
					? `bg-white hover:bg-${ color }-100`
					: `bg-${ color }-50 hover:bg-${ color }-100`)
			}
			onDoubleClick={ () => handleRowDoubleClick(row, index) }
			{ ...props }
		>
			{ Array.from({ length: Math.ceil(row.length / maxFieldsPerRow) }).map((_, fieldRowIndex) => (
				<div
					key={ fieldRowIndex }
					// className="flex gap-2 p-2"
					className="cursor-pointer flex gap-2 p-2"
				>
					{ row
						.slice(
							fieldRowIndex * maxFieldsPerRow,
							(fieldRowIndex + 1) * maxFieldsPerRow
						)
						.map((column, colIndex) => (
							<div
								key={ colIndex }
								className="flex flex-col"
								style={ { flex: 1 } }
							>
								<span className={ `text-xs text-${ color }-400` }>
									{ parseHeader(
										headers[ colIndex + fieldRowIndex * maxFieldsPerRow ]
									) }
								</span>
								<span className={ `text-sm font-semibold text-${ color }-700` }>
									{ column }
								</span>
							</div>
						)) }
					{/* Adding placeholders to ensure even column widths */ }
					{ fieldRowIndex * maxFieldsPerRow + maxFieldsPerRow >
						row.length &&
						Array.from({
							length: maxFieldsPerRow - (row.length % maxFieldsPerRow),
						}).map((_, index) => (
							<div
								key={ `placeholder-${ index }` }
								style={ { flex: 1, display: "none" } }
							></div>
						)) }
				</div>
			)
			) }
		</div>
	);
};

export default TableRow;