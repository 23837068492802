export const EnumFieldType = {
	// Grouping types
	FORM: "form",
	SECTION: "section",

	// Data types
	TEXT: "text",
	NUMBER: "number",
	BOOLEAN: "boolean",
	DROPDOWN: "dropdown",
	RADIO: "radio",
	CHECKBOX: "checkbox",
	DATE: "date",
	TIME: "time",
	DATETIME: "datetime",

	// TODO: Enhanced Types
	// MARKDOWN: "markdown",		// Allow for pure text elements (e.g. headers, paragraphs, etc.)
	// REFERENCE: "reference",		// Allow for dynamic references (e.g. nested forms, etc.)
};

export default EnumFieldType;