export function FieldInput ({ state, field, path, next, className, ...props }) {
	const { name, label, type } = field;
	const value = state ?? "";

	return (
		<input
			className={ className ? className : `border border-neutral-300 p-2 rounded-md focus:outline-none focus:border-sky-300 focus:bg-sky-100 focus:text-sky-500 shadow-sm flex-1` }
			type={ type }
			name={ name }
			placeholder={ field?.attr?.placeholder || label }
			min={ field?.validation?.min }
			max={ field?.validation?.max }
			step={ field?.validation?.step }
			{ ...(field?.attr ?? {}) }
			value={ value }
			onChange={ e => next(e.target.value) }
			{ ...props }
		/>
	);
};

export default FieldInput;