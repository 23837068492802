import { useState, useEffect } from "react";

/**
 * This is really just abstracted here because it's identical between the grid and normal table components.
 * It's pretty gnarly, but it has some nice features and is pretty flexible.
 * - Input data as either "table arrays" ({ headers: [...], rows: [...] }) or "record arrays" ([...{kvps}])
 * 		- If you use record arrays, be sure to set the `asRecords` prop to true
 * 		- NOTE: "headers" are extracted from the first record in the array, so make sure all records have the same keys
 * - Asc/desc column sorting
 * - Pagination
 * - Searching and filtering via the omnibox
 * 		- Pagination is also linked to search, so you can page through search results
 * - Variable rows per page and max pagination buttons
 */
export function useTable ({
	data = [],
	headers: overrideHeaders,
	asRecords,
	transformer,
	rowsPerPageOptions,
	rowsPerPageDefaultIndex,
}) {
	let headers = [],
		rows = [];
	if (asRecords) {
		if (overrideHeaders) {
			// override headers is provided
			headers = overrideHeaders;
			rows = data.map((record) => {
				return headers.map((header) => record?.[ header ]);
			});
		} else {
			// default behavior when override headers is not provided
			headers = Object.keys(data?.[ 0 ] ?? []);
			rows = data.map((record) => Object.values(record));
		}
	} else {
		headers = overrideHeaders ?? data.headers;
		rows = data.rows.map((row) => {
			// If overrideHeaders is provided, filter data.rows to match the headers in overrideHeaders
			return overrideHeaders ? row.filter((_, index) => overrideHeaders.includes(headers?.[ index ])) : row;
		});
	}

	const [ currentPage, setCurrentPage ] = useState(1);
	const [ rowsPerPage, setRowsPerPage ] = useState(rowsPerPageOptions[ rowsPerPageDefaultIndex || 0 ]);
	const [ searchTerm, setSearchTerm ] = useState("");
	const [ sortConfig, setSortConfig ] = useState({
		key: "",
		direction: "",
	});
	const [ filteredRows, setFilteredRows ] = useState(rows);

	useEffect(() => {
		setCurrentPage(1);
	}, [ rowsPerPage, searchTerm ]);

	useEffect(() => {
		let filteredData = [ ...rows ];

		if (searchTerm) {
			filteredData = filteredData.filter((row) =>
				row.some((cell) =>
					cell &&
					cell.toString().toLowerCase().includes(searchTerm.toLowerCase())
				)
			);
		}

		if (sortConfig.key) {
			filteredData.sort((a, b) => {
				const aValue = a[ headers.indexOf(sortConfig.key) ];
				const bValue = b[ headers.indexOf(sortConfig.key) ];

				if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
					const parsedA = parseFloat(aValue);
					const parsedB = parseFloat(bValue);
					return sortConfig.direction === "asc"
						? parsedA - parsedB
						: parsedB - parsedA;
				} else if (typeof aValue === "string" && typeof bValue === "string") {
					return sortConfig.direction === "asc"
						? aValue.localeCompare(bValue)
						: bValue.localeCompare(aValue);
				} else {
					if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
					if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
					return 0;
				}
			});
		}

		setFilteredRows(filteredData);
	}, [ sortConfig, searchTerm ]);

	const indexOfLastRow = currentPage * rowsPerPage;
	const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow).map((row, index) => {
		let newRow = row;

		if (transformer) {
			newRow = transformer(row, headers, data);
		}

		return newRow;
	});
	const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

	const handleSearch = (event) => {
		setSearchTerm(event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const handleFirstPage = () => {
		setCurrentPage(1);
	};

	const handleLastPage = () => {
		setCurrentPage(totalPages);
	};

	const handleRowsPerPageChange = (magnitude) => {
		setRowsPerPage(magnitude);
		setCurrentPage(1);
	};

	const sortColumn = (key) => {
		let direction = "asc";
		if (sortConfig.key === key && sortConfig.direction === "asc") {
			direction = "desc";
		}
		setSortConfig({ key, direction });
	};

	return { headers, rows, currentPage, rowsPerPage, searchTerm, sortConfig, filteredRows, indexOfFirstRow, indexOfLastRow, currentRows, totalPages, handleSearch, handlePageChange, handleFirstPage, handleLastPage, handleRowsPerPageChange, sortColumn, setSortConfig };
};

/**
 * This function parses a header string into a more readable format,
 * by leveraging spaces where the regex below suggests.
 */
export function parseHeader (header) {
	if (header.includes(" ")) {
		return header;
	}

	let result = header.replace(/([a-z])([A-Z]+)/g, "$1 $2");	// Add space between lower and upper case letters (e.g. "HelloWorld" -> "Hello World")
	result = result.replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2");	// Account for acronyms (e.g. "HelloHTMLWorld" -> "Hello HTML World")
	result = result.replace(/[_-]/g, " ");						// Replace underscores and dashes with spaces (e.g. "Hello_World" -> "Hello World", "Hello-World" -> "Hello World")

	return result;
};

/**
 * This function takes a row and a list of headers and creates a record object.
 * Primarily, this is used in cases where you want to do something with a particular
 * row (as such, it provides you some flexibility in how you want to use the data).
 */
export const createRowRecord = (row, headers = []) => {
	const record = {};
	headers.forEach((header, index) => {
		record[ header ] = row[ index ];
	});

	return { record, row, headers };
};

export default {
	useTable,
	parseHeader,
	createRowRecord,
};