export function isPOJO (obj) {
	if (obj === null || typeof obj !== 'object') {
		return false;
	}

	const prototype = Object.getPrototypeOf(obj);
	return prototype === null || prototype === Object.prototype;
};

export function debounce (callback, delay) {
	let timeoutId;

	return function (...args) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			callback.apply(this, args);
		}, delay);
	};
};

export default {
	isPOJO,
	debounce,
};