import { useState } from "react";
import { Tab } from "@headlessui/react";

import { BuyerSummary } from "./BuyerSummary";
import { CompareBuyerSummary } from "./CompareBuyerSummary";
import { CompareBuyerSellerSummary } from "./CompareBuyerSellerSummary";

const TabWrapper = ({ label }) => {
	return (
		<Tab className={ ({ selected }) => `w-full py-2.5 text-sm leading-5 font-medium rounded-lg focus:outline-none focus:ring-neutral-300 focus:ring-1 ${ selected ? "bg-white border border-solid border-neutral-200 text-neutral-500" : "text-opacity-80 hover:bg-neutral-200 text-neutral-400" }` }>
			{ label }
		</Tab>
	);
};

export function CompareSolves ({ children, selected, solutionSpaces, current, next }) {
	const [ index, setIndex ] = useState(1);	// Default to "Comparison" tab

	if (!next) {
		return (
			<>
				{ children }
			</>
		);
	}

	return (
		<div className="w-full">
			<Tab.Group defaultIndex={ index } onChange={ setIndex }>
				<Tab.List className="flex p-1 space-x-1 bg-neutral-100 rounded-xl">
					<TabWrapper label={ `Current` } selected={ index === 0 } />
					<TabWrapper label={ `Comparison` } selected={ index === 1 } />
					<TabWrapper label={ `Provisional` } selected={ index === 2 } />
				</Tab.List>

				<Tab.Panels>
					<Tab.Panel className="bg-white rounded-xl px-2">
						<BuyerSummary
							summary={ current }
						/>
					</Tab.Panel>
					<Tab.Panel className="bg-white rounded-xl px-2">
						<CompareBuyerSummary
							selected={ selected }
							solutionSpaces={ solutionSpaces }
							current={ current?.buyer }
							next={ next?.buyer }
						/>
						<br className="my-2" />
						<CompareBuyerSellerSummary
							current={ current?.buyerSeller }
							next={ next?.buyerSeller }
						/>
					</Tab.Panel>
					<Tab.Panel className="bg-white rounded-xl px-2">
						<BuyerSummary
							summary={ next }
						/>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
};

export default CompareSolves;