import { useEffect, useState } from "react";
import { clone } from "../../modules/form/lib/util";

import { Banner } from "../Banner";

const copyToClipboard = value => {
	try {
		navigator.clipboard.writeText(value)
			.then(() => {
				console.log("Text copied to clipboard");
			})
			.catch(err => {
				console.error("Failed to copy text: ", err);
			});
	} catch (err) {
		console.error("Error in onClick handler: ", err);
	}
};
const reorderKeys = (obj, extraRows) => {
	const order = [ "FinalCloseRate", "FinalMarginPercent", "TotalPostsToTakeAfterReturns", "FinalMargin" ];
	const ordered = {};

	extraRows.forEach(row => {
		ordered[ row[ 0 ] ] = row[ 1 ];
	});

	order.forEach(key => {
		if (obj[ key ]) {
			ordered[ key ] = obj[ key ];
		}
	});

	Object.keys(obj).forEach(key => {
		if (!ordered[ key ]) {
			ordered[ key ] = obj[ key ];
		}
	});

	return ordered;
};

export const CompareBuyerSummary = ({ selected, solutionSpaces, current, next }) => {
	const [ extraRows, setExtraRows ] = useState({
		current: [],
		next: [],
	});

	const { current: currentSolutionSpace, next: nextSolutionSpace } = solutionSpaces;
	/* This insanity is needed because you have to interpolate the data from the solution space responses
	* in order to properly project the change in margin.  That data is deeply nested, so it just looks
	* like a bunch of nonsense.
	*/
	useEffect(() => {
		if (currentSolutionSpace?.status === "complete" && nextSolutionSpace?.status === "complete") {
			let currentData = Object.values(currentSolutionSpace?.state?.payload?.[ selected.DTXID ]?.plotdata);
			let nextData = Object.values(nextSolutionSpace?.state?.payload?.[ selected.DTXID ]?.plotdata);

			let currentCloseRate = current?.state?.payload?.[ 0 ]?.[ 0 ]?.FinalCloseRate;
			let nextCloseRate = next?.state?.payload?.[ 0 ]?.[ 0 ]?.FinalCloseRate;

			// Find the index where the entry's FinalCloseRate is equal to or greater than the currentCloseRate
			let currentCloseRateIndex = currentData.findIndex(entry => entry?.FinalCloseRate >= currentCloseRate);
			if (currentCloseRateIndex > 0 && currentData?.[ currentCloseRateIndex ]?.FinalCloseRate > currentCloseRate) {
				currentCloseRateIndex--;
			}

			// Do the same for nextCloseRate
			let nextCloseRateIndex = nextData.findIndex(entry => entry?.FinalCloseRate >= nextCloseRate);
			if (nextCloseRateIndex > 0 && nextData?.[ nextCloseRateIndex ]?.FinalCloseRate > nextCloseRate) {
				nextCloseRateIndex--;
			}

			setExtraRows({
				current: [
					[ "FinalMargin", currentData?.[ currentCloseRateIndex ]?.Margin ],
				],
				next: [
					[ "FinalMargin", nextData?.[ nextCloseRateIndex ]?.Margin ],
				],
			});
		}
	}, [ currentSolutionSpace, nextSolutionSpace ]);

	if (!current || !next) {
		return <Banner color="rose" header="Error" body="A required Buyer data object was missing or empty" />;
	}

	let state = current?.state?.payload?.[ 0 ]?.[ 0 ];
	let nextState = next?.state?.payload?.[ 0 ]?.[ 0 ];
	let comparison = {};

	const constrainValue = (key, value) => {
		if (key === "DefaultSolveType") {
			if (value === 1) {
				value = "Max Gross Profit Margin";
			} else if (value === 2) {
				value = "Max Close Rate";
			}
		} else if (typeof value === "number" || /^\d+$/.test(value)) {
			if (key.includes("Rate") || key.includes("Percent") || key === "CurrentMargin") {
				value = (value * 100).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %";
			} else if (key.includes("Revenue") || key === "FinalMargin") {
				value = "$ " + value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			} else {
				value = value.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
			}
		}

		return value;
	};

	if (!state || !nextState) {
		return null;
	} else {
		state = reorderKeys(state, extraRows.current);
		nextState = reorderKeys(nextState, extraRows.next);
	}

	const formattedState = clone(state);
	const formattedNextState = clone(nextState);

	for (const key in state) {
		const currentEntry = state[ key ];
		const nextEntry = nextState[ key ];
		let nextValue = nextState[ key ];

		if (currentEntry !== nextState[ key ]) {
			// Ignore changes beyond 4 decimal places
			if (typeof currentEntry === "number" && typeof nextEntry === "number" && (currentEntry.toFixed(4) === nextEntry.toFixed(4))) {
				continue;
			}

			if (key === "DefaultSolveType") {
				if (nextValue === 1) {
					nextValue = "Max Gross Profit Margin";
				} else if (nextValue === 2) {
					nextValue = "Max Close Rate";
				}

				comparison[ key ] = (
					<div className="text-amber-500">{ nextValue }</div>
				);
			} else if (typeof nextValue === "string") {
				nextValue = constrainValue(key, nextValue);

				comparison[ key ] = (
					<div className="text-amber-500">{ nextValue }</div>
				);
			} else if (typeof nextValue === "number") {
				let delta = nextValue - currentEntry;
				nextValue = constrainValue(key, delta);

				comparison[ key ] = delta > 0 ? (
					<div className="text-green-500">+{ nextValue }</div>
				) : (
					<div className="text-red-500">{ nextValue }</div>
				);
			}
		}

		formattedState[ key ] = constrainValue(key, currentEntry);
		formattedNextState[ key ] = constrainValue(key, nextEntry);
	}

	return (
		<>
			<div className="container mx-auto cursor-default my-4">
				<div className="flex flex-row justify-center text-center text-xs text-neutral-500 font-bold mb-2">
					<div className="text-right w-1/6">&nbsp;</div>
					<div className="flex-1">Current</div>
					<div className="flex-1">Comparison</div>
					<div className="flex-1">Provisional</div>
				</div>
				{/* { Object.keys(state).map((key, index) => ( */ }
				{ Object.keys(comparison).map((key, index) => (
					<div
						key={ index }
						className="flex flex-row justify-center text-center text-xs text-neutral-500 font-bold hover:bg-neutral-100 border-b border-b-neutral-200"
					>
						<div className="text-right py-2 pr-2 w-1/6">{ key.replace(/([A-Z])/g, " $1").trim() }</div>
						<div className="cursor-copy py-2 flex-1 font-normal text-neutral-400 tracking-wider hover:bg-neutral-200 hover:text-neutral-500" onClick={ e => copyToClipboard(state[ key ]) }>{ formattedState[ key ] }</div>
						<div className="flex-1 py-2 font-bold text-neutral-400 tracking-wider">{ comparison[ key ] }</div>
						<div className="cursor-copy py-2 flex-1 font-normal text-neutral-400 tracking-wider hover:bg-neutral-200 hover:text-neutral-500" onClick={ e => copyToClipboard(nextState[ key ]) }>{ formattedNextState[ key ] }</div>
					</div>
				)) }
			</div>
		</>
	);
};

export default CompareBuyerSummary;